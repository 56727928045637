import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Paper,
  Box,
  styled,
  Grid,
  Button,
  InputBase,
  Stack,
  MenuItem,
  FormControl,
  TextField,
  InputLabel,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Recentsearch from "../../Components/Partsearch/Recentsearch";
import ProductGrid from "../../Components/Product/ProductGrid";
import PartSearchExtender from "../../Components/Header/PartSearchExtender";
import bannerbg from "../../Images/partsearch-banner-image-new-bg.jpg";
import { CentralTurbosCategorySlider } from "../Product/CentralTurbo/CentralTurbosCategory";

//import bannerbg from "../../Images/OPCpartsearch-banner-image.jpg";
import GridApifetch from "./GridApiFetch";
import {
  IFeatureproductProps,
  IPartsearchCategoryProps,
  IPartsearchAttributeTypeProps,
  IPartsearchAttributeValueProps,
  IPartsearchApplicationValueProps,
  IProductProps,
  ICompetitorFilter,
} from "../../Types/MicrositeType";
import {
  trigger_rest,
  addAttributes,
  clearAttributes,
  addapplication,
  clearApplication,
  trigger,
  addExCrossReffilter,
  addVehicleSearchFilterExists,
  addPartStatusFilter,
} from "../../Redux/Counterslice";
import {
  GetData,
  GetDataMultiResults,
  GetauthHeader,
} from "../../API/GetDataFromApi";
import { ProductList } from "../../Components/Product/ProductList";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios, { AxiosResponse } from "axios";
import { EXTENDER_CROSSREFERENCE_FILTER } from "../../Redux/ReduxConstants";
import ProductLoader from "../ProductLoader";
import { useNavigate } from "react-router-dom";
import eventEmitter from "./eventEmmiter";

const BannerSearch = () => {
  const [showbanner, setshowbanner] = useState<boolean>(true);
  const [showLoader, setshowLoader] = useState<boolean>(false);
  const [featureparts, setFeatureparts] = useState<IFeatureproductProps[]>([]);
  const [AttributeType, setAttributeType] = useState<string | undefined>("");
  const [Crossrefserachedby, setCrossrefserachedby] = useState<
    string | undefined
  >("");
  const [competitorNumberFilterby, setcompetitorNumberFilterby] = useState("");
  const [showcrossrefsearchby, setshowcrossrefsearchby] = useState(false);
  const [Sortby, setSortby] = useState<string | undefined>("");
  const [product, setProduct] = useState<IProductProps[]>([]);
  const [shoppingCart, setshoppingCart] = useState(true);

  const [CompetitorFilter, setCompetitorFilter] = useState<ICompetitorFilter[]>(
    []
  );

  const [partStatusFilterBy, setPartStatusFilterBy] = useState<
    string | undefined
  >("");

  const navigate = useNavigate();
  const selectData = (state: any) => state;
  const data = useSelector(selectData);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    extBrandFilter: data.EXTENDER_BRAND_FILTER.brandId,
    extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
    extcompetitiorPartNumber:
      data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    categoryFilter: data.CATEGORY_FILTER.categoryName,
    brandFilter: data.BRAND_FILTER.brandId,
    descriptionFilterr: data.DESCRIPTION_FILTER.descriptionId,
    YearFilter: data.YEAR_FILTER.YearName,
    makefilter: data.MAKE_FILTER.MakeName,
    ModelFilter: data.MODEL_FILTER.ModelName,
    EngineFilter: data.ENGINE_FILTER.EngineName,
    AttributeFiltervalus: data.ATTRIBUTE_FILTER_WITH_VALUE.items,
    ApplicationFiltervalus: data.APPLICATION_FILTER_WITH_VALUE.items,
    EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
    PartStatus: data.EXTRA_FILTERS.PartStatus,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      extBrandFilter: data.EXTENDER_BRAND_FILTER.brandId,
      extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
      extcompetitiorPartNumber:
        data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
      categoryFilter: data.CATEGORY_FILTER.categoryName,
      brandFilter: data.BRAND_FILTER.brandId,
      descriptionFilterr: data.DESCRIPTION_FILTER.descriptionId,
      YearFilter: data.YEAR_FILTER.YearName,
      makefilter: data.MAKE_FILTER.MakeName,
      ModelFilter: data.MODEL_FILTER.ModelName,
      EngineFilter: data.ENGINE_FILTER.EngineName,
      AttributeFiltervalus: data.ATTRIBUTE_FILTER_WITH_VALUE.items,
      ApplicationFiltervalus: data.APPLICATION_FILTER_WITH_VALUE.items,
      EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
      PartStatus: data.EXTRA_FILTERS.PartStatus.PartStatus,
    }));
    setshoppingCart(data.MS_SETTINGS_DATA.shoppingcart);
  }, [data]);

  const BrandData = (state: any) => state.BRAND_FILTER;
  const DescData = (state: any) => state.DESCRIPTION_FILTER;
  const CategoryData = (state: any) => state.CATEGORY_FILTER;
  const YearData = (state: any) => state.YEAR_FILTER;
  const MakeData = (state: any) => state.MAKE_FILTER;
  const ModelData = (state: any) => state.MODEL_FILTER;
  const engineData = (state: any) => state.ENGINE_FILTER;
  const attributeData = (state: any) => state.ATTRIBUTE_FILTER_WITH_VALUE.items;
  const applicationData = (state: any) =>
    state.APPLICATION_FILTER_WITH_VALUE.items;

  const enteredData = (state: any) => state.EXTENDER_TEXT_SEARCH;
  const Attributefilter = (state: any) => state.EXTRA_FILTERS.Subattributes;
  const Applicationfilter = (state: any) => state.EXTRA_FILTERS.SubApplications;

  // const Categoryfilterdata = useSelector(CategoryData);
  // const Descfilterdata = useSelector(DescData);
  // const Brandfilterdata = useSelector(BrandData);
  // const EnteredText = useSelector(enteredData);
  // const YearFilterData = useSelector(YearData);
  // const MakeFilterData = useSelector(MakeData);
  // const ModelFilterData = useSelector(ModelData);
  // const engineFilterData = useSelector(engineData);

  // const attributeFilterData = useSelector(Attributefilter);
  // const applicatioFilternData = useSelector(Applicationfilter);

  // const newFilteredArray = state.AttributeFiltervalus.filter(
  //   (value: any) => value.AttributeName !== Keyname
  // );
  // const handleCrossrefChange = (event: SelectChangeEvent) => {
  //   console.log("CrossrefChange", event.target.value);
  //   setCrossrefserachedby(event.target.value as string);
  //   console.log(event.target.name);
  //   setTimeout(() => dispatch(trigger()), 200);
  // };

  // const handleSearchbyChange = (event: SelectChangeEvent) => {
  //   console.log("sort", event.target.value);
  //   setSortby(event.target.value as string);
  //   setTimeout(() => dispatch(trigger()), 200);
  // };

  function resetCompetiter() {
    setCompetitorFilter([]);
    setshowcrossrefsearchby(false);
    setCrossrefserachedby("");
    setPartStatusFilterBy("");
    setcompetitorNumberFilterby("");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    eventEmitter.on("triggerresetCompetiter", resetCompetiter);
    (async () => {
      GetDataMultiResults(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-top-products"
      ).then((results?) => {
        //console.log(results, " res from multi");
        setProduct(results.data);
      });
    })();

    return () => {
      eventEmitter.off("triggerresetCompetiter", resetCompetiter);
    };
  }, []);

  const destinationArray = state.AttributeFiltervalus.map((obj: any) => ({
    attributeId: obj.Attributeid,
    attributeValue: obj.AttributeValue,
    fromValue: obj.Attributefrom,
    toValue: obj.Attributeto,
  }));

  // const newFilteredAppArray = state.ApplicationFiltervalus.filter(
  //   (value: any) => value.ApplicationName !== Keyname
  // );

  const appdesitinationarray = state.ApplicationFiltervalus.map((obj: any) => ({
    applicationId: obj.Applicationid,
    applicationValue: obj.ApplicationValue,
  }));

  function ShwAtribute() {
    if (state.descriptionFilterr !== "") {
      // setkeyName("Attribute");
      // setAttributeType("Attribute Search");
      dispatch(addAttributes("Attribute Search"));
    }
    // closedropdown();
  }
  function Showapplication() {
    if (
      data.YEAR_FILTER.YearName !== "" ||
      data.MAKE_FILTER.MakeName !== "" ||
      data.MODEL_FILTER.ModelName !== "" ||
      data.ENGINE_FILTER.EngineName !== ""
    ) {
      // setkeyName("Application");
      setAttributeType("Application Additional Search");
      dispatch(addapplication("Application Additional Search"));
      // setshowapp("showapp");
    }
  }
  const CustomerId = localStorage.getItem("CustomerId");
  let raw = JSON.stringify({
    brandId: state.extBrandFilter,
    competitorId: state.extcompetitorID,
    orderBy: Sortby,
    keyvalue:
      // state.extcompetitorID == "" &&
      // state.extcompetitiorPartNumber == "" &&
      state.extBrandFilter == "" || Crossrefserachedby != ""
        ? state.EnteredText
        : "", //  add the EnteredText
    make: state.makefilter,
    model: state.ModelFilter,
    year: state.YearFilter,
    engine: state.EngineFilter,
    category: state.categoryFilter,
    brandFilter: state.brandFilter,
    descriptions: state.descriptionFilterr,
    attribute: "",
    applicationfilter: "",
    competitorPartno: state.extcompetitiorPartNumber,
    sessionid: "",
    flag: Crossrefserachedby !== "" ? "narrowsearch" : "",
    searchOption: Crossrefserachedby,
    attributeFilters: destinationArray,
    applicationFilters: appdesitinationarray,
    customerId:
      CustomerId !== undefined && CustomerId !== null && CustomerId !== ""
        ? CustomerId
        : 0,
    userType: "",
    partStatus: state.PartStatus,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (
      state.extBrandFilter !== "" ||
      state.extcompetitorID !== "" ||
      state.categoryFilter !== "" ||
      state.brandFilter !== "" ||
      state.descriptionFilterr !== "" ||
      state.extcompetitiorPartNumber !== "" ||
      state.EnteredText !== "" ||
      state.YearFilter !== "" ||
      state.makefilter !== "" ||
      state.ModelFilter !== "" ||
      state.EngineFilter !== "" ||
      state.AttributeFiltervalus.length !== 0 ||
      state.ApplicationFiltervalus.length !== 0 ||
      (state.AttributeFiltervalus.length !== 0 &&
        state.descriptionFilterr !== "")
    ) {
      setFeatureparts([]);
      setshowcrossrefsearchby(false);

      setshowbanner(false);
      setshowLoader(true);
      (async () => {
        GridApifetch(raw, EnvData).then((res: any) => {
          //console.log("Loader for grid", res);
          if (res.length > 0) {
            setFeatureparts(res);
            setshowLoader(false);
            //console.log("Loader ee", res[0].reDirect);
            if (res[0].reDirect == 1) {
              // console.log("Loader 1");
              navigate("/Category/" + res[0].partNumber);
            } else {
              const crossrefsearchbyArray = res.filter(
                (value: any) => value.crossRef > 0
              );
              if (crossrefsearchbyArray.length > 0) {
                setshowcrossrefsearchby(true);
              } else {
                setshowcrossrefsearchby(false);
              }

              if (state.EnteredText !== "") {
                CheckCrossRefFilter(state.EnteredText);
              }
              const ApplicationFilterExistsArray = res.filter(
                (value: any) => value.isResultHavingApplication == true
              );
              // console.log(
              //   ApplicationFilterExistsArray,
              //   " from fliterss Test  array"
              // );
              if (ApplicationFilterExistsArray.length == 0) {
                dispatch(addVehicleSearchFilterExists(false));
              } else {
                dispatch(addVehicleSearchFilterExists(true));
              }

              if (state.descriptionFilterr !== "") {
                ShwAtribute();
              }
              if (
                state.YearFilter !== "" ||
                state.makefilter !== "" ||
                state.ModelFilter !== "" ||
                state.EngineFilter !== ""
              ) {
                Showapplication();
              }
            }
          } else {
            // console.log("Loader no res", res);
            setshowLoader(false);
            dispatch(addVehicleSearchFilterExists(true));
            setFeatureparts([]);
            setshowcrossrefsearchby(false);
          }
        });
      })();
    } else {
      setshowbanner(true);
    }
  }, [data.RESET_FUNCTION]);

  function CheckCrossRefFilter(txt: string) {
    // console.log("CheckCrossRefFilter", txt);
    (async () => {
      const requestOptions = {
        method: "POST",
        headers: { Requestkey: EnvData?.REACT_APP_APIREQUESTKEY },
      };
      //console.log("CheckCrossRefFilter", requestOptions);
      var rawd = "";
      GetData(
        EnvData?.REACT_APP_APIURL +
          "/microsite-api-v1/get-part-search-competitor-filter?SearchText=" +
          txt,
        rawd
      )
        .then((response: any) => {
          //console.log("CheckCrossRefFilter", response);
          //console.log(response);
          // const data: ICompetitorFilter[] = response.data;
          setCompetitorFilter(response);
        })
        .catch((error: any) => {
          console.log(error, "CheckCrossRefFilter");
        });
    })();
  }

  function FilterbyCompetitor(resp: any, index: any) {
    //console.log(resp.competitorvalue, "CheckCrossRefFilter121");
    // setcompetitorNumberFilterby(resp.competitorvalue);
    let p = resp.competitorvalue;
    let arr = p.split(" - ");
    let Compnumber = arr[0];
    let type = {
      type: EXTENDER_CROSSREFERENCE_FILTER,
      CompetitorId: resp.competitorID,
      CompetitorpartNumber: resp.partNumber,
    };

    dispatch(addExCrossReffilter(type));
    setTimeout(() => dispatch(trigger()), 200);
  }

  function FilterbyPartStatus(status: any) {
    let type = {
      PartStatus: status,
    };
    dispatch(addPartStatusFilter(type));
    setTimeout(() => dispatch(trigger()), 200);
  }
  return (
    <>
      {EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (
        <>
          {showbanner == true ? (
            <>
              <div className="central-turbos-part-extender">
                <Typography
                  variant="subtitle1"
                  className="central-turbos-title"
                >
                  Part Search
                </Typography>
                <PartSearchExtender _fromPage={"PartSearch"} />
                <CentralTurbosCategorySlider
                  _product={product}
                  partsearch={true}
                />
              </div>
            </>
          ) : (
            <>
              <div className="">
                <Recentsearch />
                {shoppingCart == true ? (
                  <FormControl className="sort-by">
                    <InputLabel id="demo-simple-select-helper-label">
                      Sort By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      value={Sortby || ""}
                      onChange={(event: any) => {
                        setSortby(event.target.value);
                        setTimeout(() => dispatch(trigger()), 200);
                      }}
                      label=" Sort By"
                    >
                      <MenuItem value="0">Availabilty</MenuItem>
                      <MenuItem value="1">Price-Ascending</MenuItem>
                      <MenuItem value="2">Price-Descending</MenuItem>
                      <MenuItem value="3">
                        Availabilty + Ascending Price
                      </MenuItem>
                      <MenuItem value="4">
                        Availabilty + Descending Price
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                {showcrossrefsearchby == true ? (
                  <FormControl className="cross-search-by">
                    <InputLabel id="demo-simple-select-helper-label">
                      Filter By (Contains, Begins...)
                    </InputLabel>
                    <Select
                      defaultValue="Contains"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      label="Filter By (Contains, Begins...)"
                      value={Crossrefserachedby || ""}
                      onChange={(event: any) => {
                        setCrossrefserachedby(event.target.value);
                        setTimeout(() => dispatch(trigger()), 200);
                      }}
                    >
                      <MenuItem value="Contains">Contains</MenuItem>
                      <MenuItem value="Begins With">Begins With</MenuItem>
                      <MenuItem value="Ends With">Ends With</MenuItem>
                      <MenuItem value="Equals">Equals</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}

                {CompetitorFilter.length > 0 ? (
                  <FormControl className="cross-search-by">
                    <InputLabel id="demo-simple-select-helper-label">
                      Filter by Competitor #
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      sx={{ maxHeight: "300px" }}
                      label=" Filter by Competitor #"
                      value={competitorNumberFilterby || ""}
                      // onChange={(event: any) => {

                      //   setcompetitorNumberFilterby(event.target.value);
                      //   // setTimeout(() => dispatch(trigger()), 200);
                      // }}
                      onChange={(event: any) => {
                        const selectedValue = event.target.value;
                        // console.log("CheckCrossRefFilwwter", selectedValue);
                        setcompetitorNumberFilterby(selectedValue);
                      }}
                    >
                      {CompetitorFilter.map((subres: any, index: number) => (
                        <MenuItem
                          key={index}
                          value={subres.competitorvalue}
                          onClick={() => FilterbyCompetitor(subres, index)}
                        >
                          {subres.competitorvalue}
                        </MenuItem>
                      ))}
                      ;
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                {localStorage.getItem("PartStatusFilter") === "true" ? (
                  <FormControl className="cross-search-by">
                    <InputLabel id="demo-simple-select-helper-label">
                      Filter by Part Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      sx={{ maxHeight: "300px" }}
                      label=" Filter by Part Status"
                      value={partStatusFilterBy || ""}
                      onChange={(event: any) => {
                        const selectedValue = event.target.value;
                        setPartStatusFilterBy(selectedValue);
                      }}
                    >
                      <MenuItem
                        key={0}
                        value={""}
                        onClick={() => FilterbyPartStatus("")}
                      >
                        {"All"}
                      </MenuItem>
                      <MenuItem
                        key={1}
                        value={"0"}
                        onClick={() => FilterbyPartStatus("0")}
                      >
                        {"Active"}
                      </MenuItem>
                      <MenuItem
                        key={2}
                        value={"3"}
                        onClick={() => FilterbyPartStatus("3")}
                      >
                        {"Discontinued"}
                      </MenuItem>
                      <MenuItem
                        key={3}
                        value={"5"}
                        onClick={() => FilterbyPartStatus("5")}
                      >
                        {"Ref Number"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
              </div>
              {showLoader == false ? (
                <ProductGrid _product={featureparts} partsearch={true} />
              ) : (
                <ProductLoader />
              )}
            </>
          )}
        </>
      ) : showbanner == true ? (
        <>
          <Paper
            sx={{
              backgroundImage: `url(${
                localStorage.getItem("BannerSearchUrl") ||
                "".replace(/ /g, "%20")
              })`,
            }}
            className="part-search-banner-bg"
          >
            <Typography
              variant="h1"
              sx={{ color: "#ffe156", fontSize: "26px" }}
            >
              {localStorage.getItem("BannerSearchText")}
            </Typography>
            <div>
              <PartSearchExtender _fromPage={"PartSearch"} />
            </div>
          </Paper>

          <ProductList _product={product} partsearch={true} />
        </>
      ) : (
        <>
          <div className="">
            <Recentsearch />
            {shoppingCart == true ? (
              <FormControl className="sort-by">
                <InputLabel id="demo-simple-select-helper-label">
                  Sort By
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  value={Sortby || ""}
                  onChange={(event: any) => {
                    setSortby(event.target.value);
                    setTimeout(() => dispatch(trigger()), 200);
                  }}
                  label=" Sort By"
                >
                  <MenuItem value="0">Availabilty</MenuItem>
                  <MenuItem value="1">Price-Ascending</MenuItem>
                  <MenuItem value="2">Price-Descending</MenuItem>
                  <MenuItem value="3">Avail + Ascending Price</MenuItem>
                  <MenuItem value="4">Avail + Descending Price</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
            {showcrossrefsearchby == true ? (
              <FormControl className="cross-search-by">
                <InputLabel id="demo-simple-select-helper-label">
                  Filter By (Contains, Begins...)
                </InputLabel>
                <Select
                  defaultValue="Contains"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  label="Filter By (Contains, Begins...)"
                  value={Crossrefserachedby || ""}
                  onChange={(event: any) => {
                    setCrossrefserachedby(event.target.value);
                    setTimeout(() => dispatch(trigger()), 200);
                  }}
                >
                  <MenuItem value="Contains">Contains</MenuItem>
                  <MenuItem value="Begins With">Begins With</MenuItem>
                  <MenuItem value="Ends With">Ends With</MenuItem>
                  <MenuItem value="Equals">Equals</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <></>
            )}

            {CompetitorFilter.length > 0 ? (
              <FormControl className="cross-search-by">
                <InputLabel id="demo-simple-select-helper-label">
                  Filter by Competitor #
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  sx={{ maxHeight: "300px" }}
                  label=" Filter by Competitor #"
                  value={competitorNumberFilterby || ""}
                  // onChange={(event: any) => {

                  //   setcompetitorNumberFilterby(event.target.value);
                  //   // setTimeout(() => dispatch(trigger()), 200);
                  // }}
                  onChange={(event: any) => {
                    const selectedValue = event.target.value;
                    // console.log("CheckCrossRefFilwwter", selectedValue);
                    setcompetitorNumberFilterby(selectedValue);
                  }}
                >
                  {CompetitorFilter.map((subres: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={subres.competitorvalue}
                      onClick={() => FilterbyCompetitor(subres, index)}
                    >
                      {subres.competitorvalue}
                    </MenuItem>
                  ))}
                  ;
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
            {localStorage.getItem("PartStatusFilter") === "true" ? (
              <FormControl className="cross-search-by">
                <InputLabel id="demo-simple-select-helper-label">
                  Filter by Part Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  sx={{ maxHeight: "300px" }}
                  label=" Filter by Part Status"
                  value={partStatusFilterBy || ""}
                  onChange={(event: any) => {
                    const selectedValue = event.target.value;
                    setPartStatusFilterBy(selectedValue);
                  }}
                >
                  <MenuItem
                    key={0}
                    value={""}
                    onClick={() => FilterbyPartStatus("")}
                  >
                    {"All"}
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value={"0"}
                    onClick={() => FilterbyPartStatus("0")}
                  >
                    {"Active"}
                  </MenuItem>
                  <MenuItem
                    key={2}
                    value={"3"}
                    onClick={() => FilterbyPartStatus("3")}
                  >
                    {"Discontinued"}
                  </MenuItem>
                  <MenuItem
                    key={3}
                    value={"5"}
                    onClick={() => FilterbyPartStatus("5")}
                  >
                    {"Ref Number"}
                  </MenuItem>
                </Select>
              </FormControl>
            ) : null}
          </div>
          {showLoader == false ? (
            <ProductGrid _product={featureparts} partsearch={true} />
          ) : (
            <ProductLoader />
          )}
        </>
      )}

      {/* <Box><ProductGrid/></Box> */}
    </>
  );
};

export default BannerSearch;

import { Header } from "./Components/Header/Header";
import "./App.css";
import Footer from "./Components/Footer";
import MyTheme from "./Components/Theme";
import { ThemeProvider } from "@mui/material";
import Home from "./Pages/Home";
import { Routes, Route } from "react-router-dom";
import { ContactUs } from "./Pages/ContactUs";
import { ViewCart } from "./Pages/ViewCart";
import Checkout from "./Pages/Checkout";
import IcartProvider from "./Components/Cart/CartContext";
import { OurProducts } from "./Pages/OurProducts";
import Services from "./Pages/Services";
import PartSearchTest from "./Pages/PartSearchTest";
import ProductDetails from "./Pages/ProductDetails";
import FeatureParts from "./Components/Product/FeatureParts";
import { RequestCatalog } from "./Pages/RequestCatalog";
import CentralTurbosHeader from "./Components/Header/CentralTurbosHeader";
import axios from "axios";

import SalesOrder from "./Pages/SalesOrder";

// import PartSearch from "./Pages/PartSearch";
import Testimonial from "./Pages/Testimonial";
import {
  IMicrositeProps,
  ICompanyProps,
  IgetImageURL,
} from "./Types/MicrositeType";
import { useEffect, useState } from "react";
import FeaturesBenefits from "./Pages/FeaturesBenefits";
import Faq from "./Pages/Faq";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Products from "./Pages/Products";
import AboutUs from "./Pages/AboutUs";
import TechBulletins from "./Pages/TechBulletins";
import { HelmetProvider } from "react-helmet-async";
import { Appgetdata } from "./API/GetDataFromApi";
import Loader from "./Components/Loader";
import CustomPage from "./Pages/CustomPage";
import { useDispatch, useSelector } from "react-redux";
import { addEnvSettings, addMsSettings } from "./Redux/Counterslice";
import TopProducts from "./Components/Product/TopProducts";
import CheckoutNew from "./Components/Checkout/CheckoutNew";
import Login from "./Pages/Login";
import OrderHistory from "./Pages/OrderHistory";
import OopsPage from "./Pages/OopsPage";
import PaymentSuccess from "./Pages/PaymentSuccess";
import SalesReceipt from "./Pages/SalesReceipt";
import { clearLocalstorageValues } from "./API/GetMicrositeSettings";
import { GetEnvironmentSettings } from "./API/GetMicrositeSettings";
import LanguageSwitcher from "./Translate/Googletranslate";
import StaticHome from "./Pages/StaticHome";
import { StaticMenuHeader } from "./Components/Header/staticMenuHeader";
import StaticTerritoryRep from "./Pages/StaticTerritoryRep";
import Maps from "./Pages/Maps";
import ProductDetailsCompetitor from "./Pages/ProductDetailsCompetitor";
import CentralTurbosHome from "./Pages/CentralTurbo/CentralTurbosHome";
import CentralTurbosFooter from "./Components/CentralTurbosFooter";
import CentralTurbosAboutUs from "./Pages/CentralTurbo/CentralTurbosAboutUs";
import CentralTurbosContact from "./Pages/CentralTurbo/CentralTurbosContact";
import CentralTurbosBrochures from "./Pages/CentralTurbo/CentralTurbosBrochures";
import CentralTurbosWarranties from "./Pages/CentralTurbo/CentralTurbosWarranties";
import Warranty from "./Pages/CentralTurbo/Warranty";
import CreditApp from "./Pages/CentralTurbo/CreditApp";
import ReturnCore from "./Pages/CentralTurbo/ReturnCore";

function App() {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const dateChecking = () => {
    var _currentDate = new Date();
    var storageDate =
      _currentDate.getMonth() +
      1 +
      "/" +
      _currentDate.getDate() +
      "/" +
      _currentDate.getFullYear();
    if (localStorage.getItem("storageDate")) {
      if (localStorage.getItem("storageDate") !== storageDate.toString()) {
        clearLocalstorageValues("Address");
        localStorage.setItem("storageDate", storageDate.toString());
      } else {
        if (
          localStorage.getItem("CartCount") &&
          localStorage.getItem("CartCount") === "0"
        ) {
          clearLocalstorageValues("Address");
        }
      }
    } else {
      localStorage.setItem("storageDate", storageDate.toString());
      if (
        localStorage.getItem("CartCount") &&
        localStorage.getItem("CartCount") === "0"
      ) {
        clearLocalstorageValues("Address");
      }
    }
  };

  let cartname: string | null = "";
  const dispatch = useDispatch();
  if (localStorage.getItem("cartName")) {
    cartname = localStorage.getItem("cartName");
  }

  const defaultSettings: IMicrositeProps = {
    banner: false,
    topCategory: false,
    topProducts: false,
    popularBrands: false,
    aboutUs: false,
    video: false,
    testimonial: false,
    micrositeName: "",
    freeshipping: false,
    tollFree: false,
    shoppingcart: false,
    freeshippingValue: "",
    tollFreeNumber: "",
    policiesandTerms: false,
    cartCount: 0,
    logo: "",
    search: false,
    newsLetter: false,
    dealerLocation: "",
    services: false,
    Product: false,
    showinFooter: false,
    faq: false,
    googleAnalyticsCode: "",
    BingWebmasterCode: "",
    partsSearchMenu: false,
    keepinTouch: true,
    facebookId: "",
    linkedinId: "",
    twitterId: "",
    skypeId: "",
    privacyPolicy: "",
    websiteUrl: "",
    emailAddress: "",
    loginOption: "",
    themeColour: "",
    copyRights: "",
    showBrandNameOrLogo: "",
    instagramId: "",
    showTechBulletins: false,
    showCrossreferences: true,
    partsearchBannerUrl: "",
    catalogueWithPricing: false,
    phonePrefix: "",
    contactEmail: "",
    captchaRequired: false,
  };

  const defaultSettingsComapny: ICompanyProps = {
    companyName: "",
    address1: "",
    phoneNo: "",
    stateName: "",
    countryName: "",
    cityName: "",
    zipCode: "",
    email: "",
    cartId: "",
    stateCode: "",
    locationUrl: "",
  };

  const [data, setData] = useState<IMicrositeProps>(defaultSettings);
  const [company, setCompany] = useState<ICompanyProps>(defaultSettingsComapny);
  const [cardImageURL, setCardImageURL] = useState<IgetImageURL[]>([]);
  const [loading, setLoading] = useState(true);
  const [_gooleAnalyticscode, setgooleAnalyticscode] = useState<string>("");
  const [_bingWebmastercode, setbingWebmastercode] = useState<string>("");

  const cookies = document.cookie;
  let statichome = true;
  useEffect(() => {
    // fetch("/settings.txt")
    //   .then((response) => response.text())
    //   .then((data) => {
    //     // Do something with the text data
    //     console.log(data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching the text file:", error);
    //   });
    localStorage.removeItem("user");
    (async () => {
      let envdata = await GetEnvironmentSettings();
      dispatch(addEnvSettings(envdata));

      if (envdata !== null) {
        await Appgetdata(cartname, envdata)
          .then((res?) => {
            console.log(res.data.micrositeInfo, " prommisee from appp");
            setData(res.data.micrositeInfo);
            dispatch(addMsSettings(res.data.micrositeInfo));
            // console.log(res.data.micrositeContactInfo, "mS SETTINGS APP");
            setCompany(res.data.micrositeContactInfo);
            setCardImageURL(res.data.cards_ImagePathResponse);
            localStorage.setItem("logo", res.data.micrositeInfo.logo);
            localStorage.setItem(
              "EasyPostApiKey",
              res.data.micrositeInfo.easyPostApiKey
            );
            localStorage.setItem(
              "BannerSearchUrl",
              res.data.micrositeInfo.partsearchBannerUrl.replaceAll(" ", "%20")
            );
            localStorage.setItem(
              "BannerSearchText",
              res.data.micrositeInfo.partSearchBannerText
            );
            localStorage.setItem(
              "PartStatusFilter",
              res.data.micrositeInfo.showPartStatusFilter
            );
            const link = document.querySelector("link[rel~='icon']");
            const newLink = document.createElement("link");
            newLink.rel = "icon";
            newLink.href = res.data.micrositeInfo.favIcon;
            if (link && link.parentNode) {
              link.parentNode.removeChild(link);
            }

            document.head.appendChild(newLink);

            setLoading(false);

            // console.log(res.data.micrositeContactInfo.companyName ,"company")
            sessionStorage.setItem(
              "CompanyName",
              res.data.micrositeContactInfo.companyName
            );
            setgooleAnalyticscode(res.data.micrositeInfo.googleAnalyticsCode);
            setbingWebmastercode(res.data.micrositeInfo.BingWebmasterCode);
            localStorage.setItem("CartId", res.data.micrositeInfo.cartId);
            sessionStorage.setItem(
              "WaterMark",
              res.data.micrositeInfo.waterMark
            );
            localStorage.setItem("Theme", res.data.micrositeInfo.themeColour);
            localStorage.setItem(
              "WarehouseId",
              res.data.micrositeInfo.warehouseId
            );
            localStorage.setItem(
              "showVehicleSearch",
              res.data.micrositeInfo.showVehicleSearch
            );

            //console.log("codeforthankyou", res.data.micrositeInfo);
            // if (res.data.micrositeInfo.googleAnalyticsCode !== "") {
            //   // const script = document.createElement("script");
            //   // script.innerText = res.data.micrositeInfo.googleAnalyticsCode;
            //   // document.head.appendChild(script);
            //   localStorage.setItem(
            //     "gScript",
            //     res.data.micrositeInfo.googleAnalyticsCode
            //   );
            // }
            //delip work for google analytics sciprt

            res.data.analyticsResponse?.map(function (result: any) {
              if (result.type == "Url") {
                const script = document.createElement("script");
                script.src = result.content;
                document.head.appendChild(script);
              } else if (result.type == "iframe") {
                const script = document.createElement("iframe");
                script.src = result.content;
                document.body.appendChild(script);
              } else {
                const script = document.createElement("script");
                script.innerHTML = result.content;
                if (result.tagId != undefined && result.tagId != "") {
                  script.id = result.tagId;
                }

                document.head.appendChild(script);
              }
            });
          })
          .catch(() => {
            console.error("error in Appgetdata");
          });
      }
      dateChecking();
      // const [data, address] = GetMicrositeSettings<IMicrositeProps[], ICompanyProps[]>(process.env.REACT_APP_APIURL + '/api/Microsite/getData', requestOptions.body, requestOptions.headers, [], [])
    })();

    const disableContextMenu = (e: any) => {
      e.preventDefault(); // Prevent the default right-click behavior
    };
    document.addEventListener("contextmenu", disableContextMenu);
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  if (loading) {
    return <Loader />;
  }
  // company.map(function (result) {
  //   sessionStorage.setItem("CompanyName", result.companyName);
  // });
  // let _gooleAnalyticscode: string = "";
  // let _bingWebmastercode: string = "";

  return (
    <HelmetProvider>
      <IcartProvider>
        <ThemeProvider theme={MyTheme({ primaryColor: data.themeColour })}>
          {window.location.pathname === "/SalesReceipt" ? null : (
            <>
              {EnvData.REACT_APP_STATIC_PAGE == "true" ? (
                data && <StaticMenuHeader _settings={data} />
              ) : EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (
                <>
                  <CentralTurbosHeader _settings={data} />
                </>
              ) : (
                <>
                  <Header _settings={data} />
                </>
              )}
            </>
          )}

          <Routes>
            <Route
              path="/"
              element={
                EnvData.REACT_APP_STATIC_PAGE == "true" ? (
                  <StaticHome _settings={data} />
                ) : EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (
                  <>
                    <CentralTurbosHome micrositeInfo={data} />
                  </>
                ) : (
                  <>
                    <Home micrositeInfo={data} />
                  </>
                )
              }
            />
            <Route
              path="/ContactUs"
              element={
                EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (
                  <CentralTurbosContact />
                ) : (
                  <ContactUs />
                )
              }
            />
            <Route path="/Viewcart" element={<ViewCart />} />
            {/* <Route path="/Checkout" element={<Checkout />} /> */}
            <Route path="/:flag/:partid?" element={<OurProducts />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/PartSearchTest" element={<PartSearchTest />} />
            <Route path="/SalesOrder" element={<SalesOrder />} />
            <Route path="/PartSearch/:Brandid?" element={<PartSearchTest />} />
            {/* <Route
              path="/PartSearch/compID=:compID?&compNumber=:Compnumber?"
              element={<PartSearch />}
            /> */}
            <Route path="/Testimonial" element={<Testimonial />} />
            <Route path="/FeaturesBenefits" element={<FeaturesBenefits />} />
            <Route path="/Faq" element={<Faq />} />
            <Route
              path="/TermsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route path="/Products/:productnum?" element={<Products />} />
            <Route
              path="/AboutUs"
              element={
                EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (
                  <CentralTurbosAboutUs />
                ) : (
                  <AboutUs _seoTitle="About Us" />
                )
              }
            />
            <Route path="/TechBulletins" element={<TechBulletins />} />
            {/* <Route path="/ProductDetails" element={<ProductDetails />} /> */}
            <Route path="/CustomPage/:val?" element={<CustomPage />} />
            {/* <Route path="/CustomPage" element={<CustomPage />} /> */}
            <Route
              path="/Category/:partnum/:productName?/:flag?"
              element={<ProductDetails />}
            />
            <Route path="/OrderHistory" element={<OrderHistory />} />
            <Route path="/FeatureParts" element={<FeatureParts />} />
            {/* <Route path="/:featuredpartsSeoTitle?" element={<FeatureParts />} /> */}
            <Route path="/TopProducts" element={<TopProducts />} />
            <Route path="/Checkout" element={<CheckoutNew />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/OopsPage" element={<OopsPage />} />
            <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
            <Route path="/SalesReceipt" element={<SalesReceipt />} />
            <Route path="/RequestCatalog" element={<RequestCatalog />} />
            {/* <Route path="/TerritoryRepresentative" element={<StaticTerritoryRep />} />  */}
            <Route
              path="/XrefCategory/:Comppartnum?/:partnum?"
              element={<ProductDetailsCompetitor />}
            />
            <Route path="/TerritoryRepresentative" element={<Maps />} />
            {/* <Route path="/CentralTurbosContact" element={<CentralTurbosContact />} />  */}
            {/* <Route path="/CentralTurbosAboutUs" element={<CentralTurbosAboutUs />} />  */}
            <Route path="/Brochures" element={<CentralTurbosBrochures />} />
            <Route path="/Warranties" element={<CentralTurbosWarranties />} />
            <Route path="/WarrantyandForms" element={<Warranty />} />
            <Route path="/CreditApplication" element={<CreditApp />} />
            <Route path="/ReturnCore" element={<ReturnCore />} />
            Maps
          </Routes>

          {window.location.pathname === "/SalesReceipt" ? null : (
            <>
              {EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (
                <>
                  <CentralTurbosFooter
                    _settings={data}
                    _address={company}
                    _cookies={cookies}
                    _cardimage={cardImageURL}
                  />
                </>
              ) : (
                <>
                  <Footer
                    _settings={data}
                    _address={company}
                    _cookies={cookies}
                    _cardimage={cardImageURL}
                  />
                </>
              )}
            </>
          )}
        </ThemeProvider>
      </IcartProvider>
    </HelmetProvider>
  );
}

export default App;

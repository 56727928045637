import { IMicrositeProps, EnvironmentSettings } from "../Types/MicrositeType";

export const categoryfilter: any = {
  type: "",
  categoryId: "",
  categoryName: "",
};

export const brandFilter: any = {
  type: "",
  brandId: "",
  brandName: "",
};

export const descriptionFilter: any = {
  type: "",
  descriptionId: "",
  desDescriptionName: "",
};

export const extenderBrand: any = {
  brandId: "",
  brandName: "",
};

export const extenderCrossReference: any = {
  CompetitorId: "",
  CompetitorpartNumber: "",
};

export const searchtext: any = {
  Text: "",
};

export const VehicleFilterYear: any = {
  YearId: "",
  YearName: "",
};

export const VehicleFilterMake: any = {
  MakeId: "",
  MakeName: "",
};

export const VehicleFilterModel: any = {
  ModelId: "",
  ModelName: "",
};

export const VehicleFilterEngine: any = {
  EngineId: "",
  EngineName: "",
};

export const AttributeFilter: any = {
  Attribute: "",
};

export const ApplicationFilter: any = {
  Application: "",
};
export const TriggerRest: any = {
  Trigger: true,
};

export const AttributeFilterWithValue: any = {
  // AttributeName:"",
  // AttributeValue:""

  items: [],
};

export const ApplicationFilterWithvalue: any = {
  items: [],
  //     {
  //     ApplicationName:"",
  //     ApplicationValue:""
  // }
};

export const FromToValues: any = {
  // Fromvalue:"",
  // Tovalue: "",

  items: [],
};

interface productData {
  CategoryId: string;
  productImage: string;
  productName: string;
}

export const productdataarr: productData[] = [];

export const MsSettings: IMicrositeProps = {
  banner: false,
  topCategory: false,
  topProducts: false,
  popularBrands: false,
  aboutUs: false,
  video: false,
  testimonial: false,
  micrositeName: "",
  freeshipping: false,
  tollFree: false,
  shoppingcart: false,
  freeshippingValue: "",
  tollFreeNumber: "",
  policiesandTerms: false,
  cartCount: 0,
  logo: "",
  search: false,
  newsLetter: false,
  dealerLocation: "",
  services: false,
  Product: false,
  showinFooter: false,
  faq: false,
  googleAnalyticsCode: "",
  BingWebmasterCode: "",
  partsSearchMenu: false,
  keepinTouch: true,
  facebookId: "",
  linkedinId: "",
  twitterId: "",
  skypeId: "",
  websiteUrl: "",
  privacyPolicy: "",
  emailAddress: "",
  loginOption: "",
  themeColour: "",
  copyRights: "",
  showBrandNameOrLogo: "",
  instagramId: "",
  showTechBulletins: false,
  showCrossreferences: true,
  partsearchBannerUrl: "",
  catalogueWithPricing: false,
  phonePrefix: "",
  contactEmail: "",
  captchaRequired: false,
};

export const extrafilters: any = {
  Attributesearch: [],
  DisplayText: "",
  Subattributes: [],
  SubApplications: [],
  Attribute: "",
  Application: "",
  Attributfilters: [],
  ApplicationFilters: [],
  CategorySelection: true,
  BrandSelection: true,
  DescriptionSelection: true,
  MakeSelection: true,
  ModelSelection: true,
  YearSelection: true,
  EngineSelection: true,
  VehicleSearchFilterExists: true,
  PartStatus: "",
};

export const partdetailsInfo: any = {
  partnumber: [],
  partflag: [],
  prevPart: "",
};

export const ordersummary: any = {
  Subtotal: "",
};

export const orderHistory: any = {
  OrderId: "",
  OrderMail: "",
};

export const FreightCount: any = {
  FreightCount: "",
};

export const CaptchaValue: any = {
  CaptchaValue: "",
};

export const Env_Settings: EnvironmentSettings = {
  REACT_APP_MICROSITENAME: "",
  REACT_APP_MICROSITEID: "",
  REACT_APP_PAYFLOW_HOST: "",
  REACT_APP_PAYFLOW_HOST_ONETIME: "",
  REACT_APP_PAYFLOW_HOST_MODE: "",
  REACT_APP_SHOWSERVICEMENU: "",
  REACT_APP_STATICPAGE: "",
  REACT_APP_PAYPALURL: "",
  REACT_APP_CARDPOINTEURL: "",
  REACT_APP_CARDPOINTEIFRAMEURL: "",
  REACT_APP_APIURL: "",
  REACT_APP_CDNURL: "",
  REACT_APP_NOIMAGEURL: "",
  REACT_APP_USERNAME: "",
  REACT_APP_PASSWORD: "",
  REACT_APP_APIKEY: "",
  REACT_APP_APIREQUESTKEY: "",
  REACT_APP_SHOWMULTILANGUAGE: "",
  REACT_APP_CADENCELOGINURL: "",
  REACT_APP_CADENCENEWACCOUNTURL: "",
  REACT_APP_STATIC_PAGE: "",
  REACT_APP_MULTILANGUAGE: "",
  REACT_APP_SHOWCATALOG: "",
  REACT_APP_SHOW_CENTRAL_TURBO:"",
  REACT_APP_RECAPTCHA_SITEKEY:""
};

//  export default Psfilter

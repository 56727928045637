import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
  IconButton,
  TextField,
  Button,
  Stack,
  FormControlLabel,
  Radio,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PaymentOption } from "../../Types/MicrositeType";
import Payment from "./Payment";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Checkout_Info from "./Checkout_Info";
import axios, { AxiosRequestConfig } from "axios";
import {
  GetAuthHeaderToken,
  Get_Data,
  GetauthHeader,
} from "../../API/GetDataFromApi";
import { useNavigate } from "react-router";
import { UserInfo } from "../../Types/MicrositeType";
import ProductLoader from "../ProductLoader";
import {
  CardpointeAuth,
  CheckUsername,
  getCadenceAPIToken,
  OrderCreation,
  OrderMail,
  PaypalAuth,
  SubmitNewsLetter,
  UpdateTmpTable,
  VoidPayment,
} from "./SubmitOrder";
import { json } from "stream/consumers";
import { TIMEOUT } from "dns";
import { useSelector } from "react-redux";
import BillingAddressFilling from "./BillingAddressFilling";
import Loader from "../Loader";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearLocalstorageValues,
  restrictedInputs,
} from "../../API/GetMicrositeSettings";
import {
  loadCaptchaEnginge,
  validateCaptcha,
  validateReCaptcha,
} from "../Captcha/react-simple-captcha";
import CachedIcon from "@mui/icons-material/Cached";
import ReCAPTCHA from "react-google-recaptcha";
// import eventEmitter from "../PartSearchTest/eventEmmiter";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "8px 0",
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface InfoComponentProps {
  statevalue: any;
  change: (name: string, value: string) => void;
  err: any;
  nxtTab: any;
  handlechange: any;
  ContactInfo: string;
  ShipAddress: string;
  ShipMethod: any | void;
  citylist: any;
  statelist: any;
  countrylist: any;
  city: any;
  state: any;
  country: any;
}

export default function PaymentOptions({
  statevalue,
  nxtTab,
  handlechange,
  ContactInfo,
  change,
  ShipAddress,
  ShipMethod,
  err,
  citylist,
  statelist,
  countrylist,
  city,
  state,
  country,
}: InfoComponentProps) {
  const [adderssExpanded, setAdderssExpanded] = React.useState<string | false>(
    "panel1"
  );
  const [showPopup, setshowPopup] = useState(false);

  const handleChangeAdderss =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setAdderssExpanded(newExpanded ? panel : false);
    };

  const [selectedValueAdderss, setSelectedValueAdderss] = React.useState("a");
  const [AddObject, setAddObject] = useState<any>(null);
  const [paySelectedValue, setPaySelectedValue] = React.useState("CreditCard");
  const [accountVal, setAccountVal] = useState("");
  const [routingVal, setRoutingVal] = useState("");
  const [cpToken, setCPToken] = useState("");
  const [PaypalToken, setPaypalToken] = useState("");
  const [SavedCard, setSavedCard] = useState("");
  const [disablePayment, setdisablePayment] = useState("");
  const [selectedval, setselectedval] = useState<[]>([]);
  const [selectedadd, setselectedadd] = useState({});
  const [zipList, setzipList] = useState<any>([]);
  // const [CountryList, setCountryList] = useState<[]>([]);
  // const [StateList, setStateList] = useState<[]>([]);
  // const [CityList, setCityList] = useState<[]>([]);
  const [showloader, setShowloader] = useState(false);
  const SubTotalFilter = (state: any) => state.ORDERSUMMARY_INFO.Subtotal;
  const SubTotaldata = useSelector(SubTotalFilter);
  const [onlineAccess, setOnlineAccess] = useState(false);
  const [submitpopup, setSubmitpopup] = useState(false);
  // const [PaymentOption, setPaymentOption] = useState<PaymentOption>({
  //   paymentType: "",
  //   referenceNum: "",
  //   paymentGateway: "",
  //   cardType: "",
  // });
  const navigate = useNavigate();
  const [popupEl, setPopupEl] = React.useState<null | HTMLElement>(null);
  const [userName, setuserName] = useState("");
  const [password, setpassword] = useState("");
  const [userNameError, setuserNameError] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [confirmpasswordError, setconfirmpasswordError] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [showSponeNumber, setshowSponeNumber] = useState(false);
  const [PhoneNumberErr, setPhoneNumbererr] = useState("");
  const $ref = useRef(null);
  const [Captcha, setCaptcha] = useState("");
  const [showCaptcha, setShowCaptcha] = useState(false);

  let Shipinfo = localStorage.getItem("ShippingInfo");
  let parsedValue = JSON.parse(Shipinfo ? Shipinfo : "");

  const selectData = (state: any) => state.MS_SETTINGS_DATA;
  const msSettings = useSelector(selectData);

  const CaptureCaptchaValue = (state: any) => state.CAPTURECAPTCHAVALUE;
  const CaptchaValue = useSelector(CaptureCaptchaValue);

  useEffect(() => {
    console.log(msSettings, "msSettings");
    console.log(msSettings.captchaRequired, "msSettings.captchaRequired");
    //setShowCaptcha(msSettings.captchaRequired);
  }, [msSettings]);

  //const navigate = useNavigate();

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const CheckOAandSubmitOrder = (event: React.MouseEvent<HTMLElement>) => {
    const trimmednumber = phoneNo
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "")
      .replaceAll("-", "");
    if (showSponeNumber === true && trimmednumber.length !== 10) {
      alert("Enter Valid Phone Number");
      if (trimmednumber.length === 0) {
        setPhoneNumbererr("Please Enter the phone number");
      }
      return;
    } else {
      if (onlineAccess === true || showCaptcha === true) {
        setSubmitpopup(true);
        if (recaptchaRef.current) {
          setCaptchaValue(null);
          recaptchaRef.current.reset();
        }

        // setTimeout(() => {
        //   loadCaptchaEnginge(6);
        // }, 200);

        // setTimeout(() => {
        //   //setPopupEl(popupEl ? null : event.currentTarget);
        //   setSubmitpopup(true);
        // }, 200);
      } else {
        SubmitOrder("", "");
      }
    }
  };

  const CreateOrderWithOnlineAccess = async () => {
    if (!captchaValue) {
      alert("Please complete the reCAPTCHA challenge");
      return;
    }

    if (
      userNameError === "" &&
      PasswordError === "" &&
      confirmpasswordError === "" &&
      userName !== "" &&
      password !== "" &&
      confirmpassword !== ""
    ) {
      const msg = await CheckUsername(userName, EnvData);
      if (msg == "") {
        const res = await validateReCaptcha(EnvData, captchaValue);
        if (res.success === true) {
          SubmitOrder(userName, password);
          setSubmitpopup(false);
        } else {
          if (recaptchaRef.current) {
            setCaptchaValue(null);
            recaptchaRef.current.reset();
          }

          alert(res.message);
          return false;
        }

        // if (validateCaptcha(Captcha) === true || showCaptcha === false) {
        //   SubmitOrder(userName, password);
        //   setSubmitpopup(false);
        // } else {
        //   alert("Captcha Does Not Match");
        //   loadCaptchaEnginge(6);
        // }
      } else {
        alert(msg);
      }
    } else {
      alert("Please Fill All Required Fields");
    }
  };

  const CreateOrderWithOutOnlineAccess = () => {
    if (validateCaptcha(Captcha) === true || showCaptcha === false) {
      setuserName("");
      setpassword("");
      setconfirmpassword("");
      SubmitOrder("", "");
      setSubmitpopup(false);
    } else {
      alert("Captcha Does Not Match");
      loadCaptchaEnginge(6);
    }
  };

  const SubmitOrder = async (Username: string, Password: string) => {
    // if (validateCaptcha(Captcha) == true) {
    setShowloader(true);
    localStorage.removeItem("OnHoldFlag");
    localStorage.removeItem("OnHoldMsg");
    const headers: any = await GetAuthHeaderToken();
    if (headers === "Token Expired") {
      navigate("/login");
      return false;
    }
    localStorage.removeItem("ShoppingcartHdrId");
    let CartId = localStorage.getItem("CartId");

    // console.log("SOCreation Step1");
    if (CartId === null || CartId === undefined || CartId === "") {
      alert("Your Cart has been Cleared.  Redirecting to Home page");
      setShowloader(false);
      navigate("/");
      return;
    }

    const ShippingInfo: any = localStorage.getItem("ShippingInfo");
    const BillingInfo: any = localStorage.getItem("BillingInfo");
    const ShippingOption: any = localStorage.getItem("DeliveryOption");

    if (ShippingInfo === null) {
      alert("Invalid Shipping Address");
      setShowloader(false);
      return;
    }
    if (BillingInfo === null) {
      alert("Invalid Billing Address");
      setShowloader(false);
      return;
    }
    if (ShippingOption === null) {
      alert("Invalid ShippingOptions");
      setShowloader(false);
      return;
    }
    if (adderssExpanded === "panel2") {
      // const aa = await eventEmitter.emit("triggerTabChange", "OPTIONS");
      localStorage.setItem("BillingAddressChanged", "1");

      if (localStorage.getItem("CustomerInfo")) {
        const loginData = localStorage.getItem("CustomerInfo");
        let loginDetails = loginData ? JSON.parse(loginData) : null;
        if (loginDetails) {
          if (localStorage.getItem("BillingInfo")) {
            const shipData = localStorage.getItem("BillingInfo");
            const shipDetails = shipData ? JSON.parse(shipData) : null;
            if (shipDetails) {
              if (
                shipDetails.Zip !== loginDetails.shippingZipCode ||
                shipDetails.CountryId !== loginDetails.shippingCountryId ||
                shipDetails.AddressLine1 !== loginDetails.shippingAddress1 ||
                shipDetails.StateId !== loginDetails.shippingStateId ||
                shipDetails.CityId !== loginDetails.shippingCityId ||
                shipDetails.PhoneNumber !== loginDetails.shippingPhone
              ) {
                localStorage.setItem("UpdateBillingAddress", "YES");
              }
            }
          }
        }
      }
      let flag = await UpdateTmpTable(
        EnvData,
        "Billing",
        CaptchaValue.CaptchaValue
      );
      if (flag !== true) {
        alert("Billing Address not Updated Properly");
        setShowloader(false);
        return;
      }
    }
    //Token Generation Logic need to check and remove from here
    var CadenceAPIToken = localStorage.getItem("authToken");
    if (
      CadenceAPIToken === "" ||
      CadenceAPIToken === null ||
      CadenceAPIToken === undefined
    ) {
      const fun = await getCadenceAPIToken(EnvData);
      if (fun == "Error") {
        alert("Getting Error While Generating Token");
        setShowloader(false);
        return;
      } else {
        CadenceAPIToken = fun;
      }
    }

    let lccptoken = localStorage.getItem("cpToken");
    let lcPaypalToken = localStorage.getItem("PaypalToken");

    if (paySelectedValue === "") {
      alert("Please Select Payment Type");
      setShowloader(false);
      return;
    } else if (
      paySelectedValue === "CreditCard" &&
      (lccptoken === "" || lccptoken === undefined || lccptoken === null)
    ) {
      alert("Please Enter Card Details");
      setShowloader(false);
      return;
    } else if (
      paySelectedValue === "Saved Card" &&
      (lccptoken === "" || lccptoken === undefined || lccptoken === null)
    ) {
      alert("Please Select One Saved Card Details Payment");
      setShowloader(false);
      return;
    } else if (
      paySelectedValue === "PayPal" &&
      (lcPaypalToken === "" ||
        lcPaypalToken === undefined ||
        lcPaypalToken === null)
    ) {
      alert("Please Enter Paypal Account Details");
      setShowloader(false);
      return;
    }

    // if (
    //   (lccptoken === "" || lccptoken === undefined || lccptoken === null) &&
    //   (lcPaypalToken === "" ||
    //     lcPaypalToken === undefined ||
    //     lcPaypalToken === null)
    // ) {
    //   alert("Please Select Payment Type");
    //   setShowloader(false);
    //   return;
    // }

    var Paymentstatus = "";

    if (
      (paySelectedValue === "CreditCard" ||
        paySelectedValue === "Saved Card") &&
      lccptoken !== "" &&
      lccptoken !== undefined &&
      lccptoken !== null
    ) {
      const paymentInfo = await CardpointeAuth(SubTotaldata, EnvData);
      // console.log("SOCreation Step4 CardPointe Payment", paymentInfo);
      if (paymentInfo == "Error") {
        alert("Getting Error on CardPointe Payment");
        Paymentstatus = "Failed";
        setShowloader(false);
      } else if (paymentInfo === "Invalid Request") {
        setShowloader(false);
        clearLocalstorageValues();
        alert(
          "There is a problem with your current shopping cart. Please contact customer service at " +
            msSettings.tollFreeNumber +
            " for assistance or email us at " +
            msSettings.contactEmail +
            ""
        );
        window.location.reload();
      } else if (paymentInfo !== "Success") {
        // alert(paymentInfo);
        setShowloader(false);
        Paymentstatus = "Failed";
      } else {
        Paymentstatus = "Success";
      }
    } else if (
      paySelectedValue === "PayPal" &&
      lcPaypalToken !== "" &&
      lcPaypalToken !== undefined &&
      lcPaypalToken !== null
    ) {
      const payapalpayment: any = await PaypalAuth(SubTotaldata, EnvData);
      // console.log("SOCreation Step4 Paypal Payment", payapalpayment);
      if (payapalpayment == "Error") {
        alert("Getting Error on Paypal Payment");
        setShowloader(false);
        Paymentstatus = "Failed";
      } else if (payapalpayment !== "Success") {
        alert(payapalpayment);
        setShowloader(false);
        Paymentstatus = "Failed";
      } else {
        Paymentstatus = "Success";
      }
    } else if (paySelectedValue === "PayViaTerms") {
      var data: PaymentOption = {
        paymentType: "PayviaTerms",
        referenceNum: "",
        paymentGateway: "",
        cardType: "",
        respCode: "",
        cardPointeProfile: "",
        cardPointeToken: "",
        cardNumber: "",
        authCode: "",
        expiryDate: "",
        errorMsg: "",
      };
      localStorage.setItem("PaymentInfo", JSON.stringify(data));
      Paymentstatus = "Success";
    }

    if (Paymentstatus === "Success") {
      var OrderStaus = "";
      const status = await OrderCreation(
        CadenceAPIToken,
        Username,
        Password,
        EnvData
      );
      // console.log("SOCreation Step5 Order Created", status);

      if (status == "Error") {
        alert("Getting Error While Generating Order");
        setShowloader(false);
        OrderStaus = "Error";
      } else if (status !== "Success") {
        // alert(status.errorMessage.replaceAll("'", ""));
        alert("Not able To create order due to technical Issue");
        setShowloader(false);
        OrderStaus = "Error";
      } else {
        await setTimeout(() => {
          localStorage.getItem("ShoppingcartHdrId");

          if (
            localStorage.getItem("ShoppingcartHdrId") !== "" &&
            localStorage.getItem("ShoppingcartHdrId") !== null &&
            localStorage.getItem("ShoppingcartHdrId") !== undefined
          ) {
            if (localStorage.getItem("NewsAndOffersText") === "true") {
              SubmitNewsLetter(EnvData);
            }
            OrderStaus = "Success";
            setPopupEl(null);
            OrderMail(Username, EnvData);
            setShowloader(false);
            navigate("/PaymentSuccess");
          } else {
            OrderStaus = "Error";
            alert("Error..! ");
          }
          return "PaymentSuccess";
        }, 1000);
      }

      if (OrderStaus === "Error") {
        const voidstatus = await VoidPayment(EnvData);
        localStorage.removeItem("PaypalToken");
        localStorage.removeItem("cpToken");
        navigate("/Checkout");
      }
    } else {
      alert("Payment Authorization Failed");
      setShowloader(false);
      return;
    }
    // } else {
    //   alert("Captcha Does Not Match");
    //   loadCaptchaEnginge(6);
    // }
  };

  function SelectMultiCity(citydata: any) {
    // for (var i = 0; i <= citydata.data.length; i++) {
    //   if (
    //     citydata.data[i].city ==
    //     statevalue.AddressLine1.label.split(",")[1].toUpperCase().trim()
    //   ) {
    //     // change('City', citydata.data[i].city);
    //     // change('CityId', citydata.data[i].cityId);
    //     //ChangeCityData(citydata.data[i]);
    //   } else {
    //     consolse.error(citydata.data[i], "response from cityy");
    //   }
    // }
  }

  useEffect(() => {
    (async () => {
      // console.log(statevalue, "name, newvalue.value")
      if (statevalue.Zip !== "" && statevalue.Country == "") {
        try {
          let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-country?zipCode=${statevalue.Zip}`;
          const countryResponse = await Get_Data(URL);

          if (
            Array.isArray(countryResponse.data) &&
            countryResponse.data.length === 1
          ) {
            let UsRL = `${
              EnvData?.REACT_APP_APIURL
            }/microsite-api-v1/get-state?zipCode=${
              statevalue.Zip
            }&countryId=${Number(countryResponse.data[0].countryId)}`;
            const stateResponse = await Get_Data(UsRL);

            if (
              Array.isArray(stateResponse.data) &&
              stateResponse.data.length === 1
            ) {
              let cityURL = `${
                EnvData?.REACT_APP_APIURL
              }/microsite-api-v1/get-city?zipCode=${
                statevalue.Zip
              }&countryId=${Number(
                countryResponse.data[0].countryId
              )}&stateId=${Number(stateResponse.data[0].stateId)}`;
              const cityResponse = await Get_Data(cityURL);

              change("Zip", statevalue.Zip);

              countrylist(countryResponse.data);
              if (countryResponse.data.length === 1) {
                // change('Country', countryResponse.data[0].country);
                // change('CountryId', countryResponse.data[0].countryId);
                // //ChangeCountryData(countryResponse.data[0]);
              } else {
                // change('Country','' );
                // change('CountryId','');
                // //ClearCountryData();
              }
              statelist(stateResponse.data);

              if (stateResponse.data.length === 1) {
                // change('State', stateResponse.data[0].state);
                // change('StateId', stateResponse.data[0].stateId);
                // //ChangeStateData(stateResponse.data[0]);
              } else {
                // change('State', '');
                // change('StateId', '');
                // //ClearStateData();
              }

              citylist(cityResponse.data);
              if (cityResponse.data.length === 1) {
                // console.log("Found city:", statevalue.AddressLine1.label);
                //SelectMultiCity(cityResponse);
                // //ChangeCityData(cityResponse.data[0]);
                // change("City", cityResponse.data[0].city);
                // change("CityId", cityResponse.data[0].cityId);
              } else {
                // change("City", "");
                // change("CityId", "");
                // //ClearCityData();
              }
            }
          }
        } catch (error) {
          console.error("Error in request:", error);
        }
      } else if (
        statevalue.Zip !== "" &&
        statevalue.Country !== "" &&
        statevalue.State !== "" &&
        statevalue.City !== ""
      ) {
        try {
          let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-country?zipCode=${statevalue.Zip}`;
          const countryResponse = await Get_Data(URL);

          if (
            Array.isArray(countryResponse.data) &&
            countryResponse.data.length === 1
          ) {
            let UsRL = `${
              EnvData?.REACT_APP_APIURL
            }/microsite-api-v1/get-state?zipCode=${
              statevalue.Zip
            }&countryId=${Number(countryResponse.data[0].countryId)}`;
            const stateResponse = await Get_Data(UsRL);

            if (
              Array.isArray(stateResponse.data) &&
              stateResponse.data.length === 1
            ) {
              let cityURL = `${
                EnvData?.REACT_APP_APIURL
              }/microsite-api-v1/get-city?zipCode=${
                statevalue.Zip
              }&countryId=${Number(
                countryResponse.data[0].countryId
              )}&stateId=${Number(stateResponse.data[0].stateId)}`;
              const cityResponse = await Get_Data(cityURL);

              change("Zip", statevalue.Zip);

              countrylist(countryResponse.data);
              if (statevalue.Country !== "") {
                // change('Country', countryResponse.data[0].country);
                // change('CountryId', countryResponse.data[0].countryId);
                //    //ChangeCountryData(countryResponse.data[0]);
              } else {
                // change('Country','' );
                // change('CountryId','');
                //    //ClearCountryData();
              }
              statelist(stateResponse.data);

              if (statevalue.State !== "") {
                // change('State', stateResponse.data[0].state);
                // change('StateId', stateResponse.data[0].stateId);
                //   //ChangeStateData(stateResponse.data[0]);
              } else {
                // change('State', '');
                // change('StateId', '');
                //  //ClearStateData();
              }

              citylist(cityResponse.data);
              if (statevalue.City !== "") {
                // console.log(
                //   "Found city---:",
                //   statevalue.AddressLine1.label.split(",")[1]
                // );

                SelectMultiCity(cityResponse);

                // //ChangeCityData(cityResponse.data[0]);
              } else {
                // //ClearCityData();
              }
            }
          }
        } catch (error) {
          console.error("Error in request:", error);
        }
      }
    })();
  }, [statevalue.Zip]);

  const checkBoxAdderss = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValueAdderss(event.target.value);
  };

  useEffect(() => {
    if (cpToken !== "") {
      setdisablePayment("credit");
    }

    if (parsedValue.PhoneNumber === "") {
      setshowSponeNumber(true);
    } else {
      setPhoneNo(parsedValue.PhoneNumber);
    }
  }, []);

  const handlePonumberchange = (event: any) => {
    localStorage.setItem("PONUMBER", event.target.value);
  };

  const handlePhonenumberchange = (event: any) => {
    const value = event.target.value.replace(/[^0-9(),\-]/g, "");
    if (value.length < 10) {
      setPhoneNumbererr("Phone number should have 10  digits.");
    } else if (value.length == 10) {
      setPhoneNumbererr("");
    } else if (value.length > 10) {
      setPhoneNumbererr("Phone number should have 10  digits.");
    }
    setPhoneNo(value);
    parsedValue.PhoneNumber = value;
    localStorage.setItem("ShippingInfo", JSON.stringify(parsedValue));
  };
  const onlineAccessChange = (event: any) => {
    setShowCaptcha(onlineAccess === true ? false : true);
    setOnlineAccess(onlineAccess === true ? false : true);
  };

  function checkPassword(val: string, txtVal: any, compare: string) {
    if (val.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,})$/)) {
      setpassword(val);
      if (compare != "") {
        setconfirmpassword(val);
      }
    } else {
      if (val.length > 0 && compare !== "C") {
        if (/\W/.test(val)) {
          setpassword(val);
          setPasswordError("");
        } else {
          setPasswordError(
            "Minimum 6 characters must be entered including  one lower Case, one Upper Case and one Number"
          );
          return false;
        }
      }
    }

    compareField(val, compare);
  }

  function compareField(val: any, compare: any) {
    var val1 = compare == "C" ? password : confirmpassword;
    if (val1 === val) {
      setconfirmpasswordError("");
    } else {
      if (val.length > 0) {
        setconfirmpasswordError("Password and Confirm Password should same");
      }
    }
  }

  const RECAPTCHA_SITE_KEY = EnvData?.REACT_APP_RECAPTCHA_SITEKEY;

  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleCaptchaChange = (value: any) => {
    setCaptchaValue(value);
    console.log("Captcha value:", value);
  };

  if (showloader)
    return (
      <div>
        {" "}
        <Loader />
      </div>
    );

  const openPopup = Boolean(popupEl);
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    // setPopupEl(popupEl ? null : event.currentTarget);
    setSubmitpopup(false);
  };

  function enterCaptcha(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let captchaVal = restrictedInputs(event.target.value);
    setCaptcha(captchaVal.toString());
  }

  const reloadCaptcha = () => {
    loadCaptchaEnginge("6");
  };

  return (
    <div>
      <Box sx={{ mt: 2 }}>
        <table className="payment-contact-info">
          <tbody>
            <tr>
              <th>
                <Typography variant="subtitle2">CONTACT</Typography>
              </th>
              <td>{parsedValue.Email}</td>
              <td
                className="payment-contact-info-change"
                onClick={() => handlechange("Contact")}
              >
                <Button color="primary" variant="contained">
                  Change
                </Button>
              </td>
            </tr>
            <tr>
              <th>
                <Typography variant="subtitle2">SHIPPING ADDRESS</Typography>
              </th>
              <td>
                {localStorage.getItem("DefaultAddress") +
                  ", " +
                  localStorage.getItem("defaultCity") +
                  ", " +
                  localStorage.getItem("defaultZip") +
                  ", " +
                  parsedValue?.Country.split("|")[1]}
              </td>
              <td
                className="payment-contact-info-change"
                onClick={() => handlechange("Addresss")}
              >
                <Button color="primary" variant="contained">
                  Change
                </Button>
              </td>
            </tr>
            <tr>
              <th>
                <Typography variant="subtitle2">SHIPPING METHOD</Typography>
              </th>
              <td>{ShipMethod} </td>
              <td
                className="payment-contact-info-change"
                onClick={() => handlechange("Method")}
              >
                <Button color="primary" variant="contained">
                  Change
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Box sx={{ mt: 2 }}>
        <div className="product-right-border">
          <Typography variant="h4" pb={1}>
            PAYMENT
            <Divider
              sx={{ bgcolor: "primary.main" }}
              className="title-divider"
            />
          </Typography>
        </div>
        <Payment
          paySelectedValue={paySelectedValue}
          setPaySelectedValue={setPaySelectedValue}
          setAccountVal={setAccountVal}
          setRoutingVal={setRoutingVal}
          setCPToken={setCPToken}
          setPaypal={setPaypalToken}
          setSavedCard={setSavedCard}
          disable={disablePayment}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <div className="product-right-border">
          <Typography variant="h4" pb={2}>
            BILLING ADDRESS
            <Divider
              sx={{ bgcolor: "primary.main" }}
              className="title-divider"
            />
          </Typography>
        </div>

        <Accordion
          expanded={adderssExpanded === "panel1"}
          onChange={handleChangeAdderss("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant="subtitle2">
              <Radio
                checked={adderssExpanded === "panel1"}
                // onChange={checkBoxAdderss}
                value="a"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              Same as Shipping Address
            </Typography>
          </AccordionSummary>
        </Accordion>
        <Accordion
          expanded={adderssExpanded === "panel2"}
          onChange={handleChangeAdderss("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant="subtitle2">
              <Radio
                checked={adderssExpanded === "panel2"}
                // onChange={checkBoxAdderss}
                value="b"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
              />
              Use a different Billing Address
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Checkout_Info
              handleDropDown={handleDropDown}
              handleLoadOptions={handleLoadOptions}
              handleInputChange={handleInputChange}
              disable={false}
              statevalue={statevalue}
              err={err}
              selectedval={selectedval}
              zipOptions={zipList}
              handlew={handlew}
              handleSelectChange={handleSelectChange}
              handleInputnumberChange={handleInputnumberChange}
              handle={handle}
              HandleZip={HandleiZip}
              SelectZip={SelectZip}
              Country={country}
              State={state}
              City={city}
            /> */}

            <BillingAddressFilling />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box sx={{ mt: 2 }}>
        <div className="product-right-border">
          <Typography variant="h4" pb={2}>
            ORDER INSTRUCTIONS
            <Divider
              sx={{ bgcolor: "primary.main" }}
              className="title-divider"
            />
          </Typography>
        </div>
        {/* <Stack direction="row">
          <FormControlLabel
            control={<Checkbox defaultChecked color="primary" />}
            label="Deliver all the items at the same time"
          />
        </Stack> */}
        <Stack direction="row">
          <TextField
            onChange={handlePonumberchange}
            fullWidth
            label="Enter PO Number"
            variant="standard"
            inputProps={{
              maxLength: 50,
            }}
          />
        </Stack>
        {showSponeNumber === true ? (
          <>
            <br></br>
            <Stack direction="row">
              <TextField
                onChange={(event) => {
                  setPhoneNo(event.target.value.replace(/[^0-9(),\-]/g, ""));
                }}
                onBlur={handlePhonenumberchange}
                value={phoneNo}
                fullWidth
                label="Enter Phone no"
                variant="standard"
                required
                inputProps={{
                  pattern: "[0-9]*",
                  minLength: 10,
                  maxLength: 15,
                }}
                onKeyUp={(e) => {
                  const inputElement = e.target as HTMLInputElement;
                  const value = inputElement.value.replace(/[^0-9(),\-]/g, "");
                  if (value.length > 9) {
                    setPhoneNumbererr("");
                  }
                }}
                error={PhoneNumberErr !== ""}
                helperText={PhoneNumberErr}
              />
            </Stack>
          </>
        ) : null}
      </Box>

      {localStorage.getItem("UserId") !== null &&
      localStorage.getItem("UserId") !== undefined &&
      localStorage.getItem("UserId") !== "" ? (
        <Box sx={{ mt: 2 }}></Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          <div className="product-right-border">
            <Typography variant="h4" pb={2}>
              REMEMBER ME
              <Divider
                sx={{ bgcolor: "primary.main" }}
                className="title-divider"
              />
            </Typography>
          </div>
          <Stack direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlineAccess}
                  onChange={onlineAccessChange}
                  // defaultChecked
                  color="primary"
                />
              }
              label="Save my information for faster checkout"
            />
          </Stack>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <Button
          color="primary"
          sx={{ textAlign: "right" }}
          variant="contained"
          onClick={(e) => CheckOAandSubmitOrder(e)}
        >
          Submit Order
        </Button>
      </Box>
      {/* <Button fullWidth variant="contained" sx={{ mt: 3 }}>
        Continue To Shipping Options
      </Button> */}

      <Dialog
        open={submitpopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xs"
        className="captcha-dialog"
      >
        <DialogTitle id="alert-dialog-title" className="dialog-title-mobile">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="cross-ref">
            {onlineAccess === true ? (
              <>
                <Box sx={{ mt: 2 }}>
                  <div className="product-right-border">
                    <Typography variant="h4" pb={2}>
                      Create Account
                      <Divider
                        sx={{ bgcolor: "primary.main" }}
                        className="title-divider"
                      />
                    </Typography>
                  </div>

                  <TextField
                    fullWidth
                    label="User Name"
                    name="username"
                    variant="standard"
                    value={userName}
                    error={userNameError !== ""}
                    helperText={userNameError}
                    sx={{ marginBottom: "10px" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setuserNameError("This Field Is Required");
                      } else {
                        setuserNameError("");
                      }
                      var username = restrictedInputs(e.target.value);
                      setuserName(username);
                    }}
                  />

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      label="Password"
                      name="password"
                      variant="standard"
                      value={password}
                      error={PasswordError !== ""}
                      helperText={PasswordError}
                      type="password"
                      sx={{ marginBottom: "10px" }}
                      // onChange={(e) => {
                      //   setpassword(e.target.value);
                      //   checkPassword(e.target.value, e, "");
                      // }}
                      onChange={(e) => {
                        var pass = restrictedInputs(e.target.value);
                        setpassword(pass);
                        if (pass === "") {
                          setPasswordError("This Field Is Required");
                        } else {
                          setPasswordError("");
                          checkPassword(pass, e, "");
                        }
                      }}
                    />
                    <Typography variant="body2" color="error" sx={{ mb: 1 }}>
                      Note : Your Password must contain minimum 6 characters,
                      including one lower Case, one Upper Case and one Number.
                      {/* order related information. */}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      name="confirmpassword"
                      variant="standard"
                      value={confirmpassword}
                      error={confirmpasswordError !== ""}
                      helperText={confirmpasswordError}
                      sx={{ mb: 2 }}
                      // onBlur={(e) => {
                      //   checkPassword(e.target.value, e, "C");
                      // }}
                      type="password"
                      onChange={(e) => {
                        var pass = restrictedInputs(e.target.value);
                        setconfirmpassword(pass);
                        if (pass === "") {
                          setconfirmpasswordError("This Field Is Required");
                        } else {
                          setconfirmpasswordError("");
                          checkPassword(pass, e, "C");
                        }
                      }}
                    />
                  </Grid>
                </Box>
              </>
            ) : null}

            {showCaptcha === true ? (
              <Box sx={{ mt: 2 }}>
                <div className="product-right-border">
                  <Typography variant="h4" pb={1}>
                    ENTER CAPTCHA
                    <Divider
                      sx={{ bgcolor: "primary.main" }}
                      className="title-divider"
                    />
                  </Typography>
                </div>
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                  For Your security please enter captcha information below
                </Typography>
                <Stack>
                  <div className="ReloadCaptcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={handleCaptchaChange}
                    />
                    {/* <canvas
                      id="canv"
                      width="100"
                      height="15"
                      ref={$ref}
                    ></canvas>
                    <Button onClick={reloadCaptcha} variant="text">
                      <CachedIcon />
                    </Button>
                    <div className="EnterAboveText">
                      <TextField
                        id="user_captcha_input"
                        label="Enter Captcha"
                        variant="outlined"
                        onChange={(e) => {
                          enterCaptcha(e);
                        }}
                      />
                    </div> */}
                  </div>
                </Stack>
              </Box>
            ) : null}

            <br></br>
            {onlineAccess === true ? (
              <>
                <Button
                  color="primary"
                  sx={{ textAlign: "right", mr: 1 }}
                  variant="contained"
                  onClick={CreateOrderWithOnlineAccess}
                >
                  Create
                </Button>
                <Button
                  color="primary"
                  sx={{ textAlign: "right" }}
                  variant="contained"
                  onClick={CreateOrderWithOutOnlineAccess}
                >
                  No, I will create later
                </Button>
              </>
            ) : (
              <Button
                color="primary"
                sx={{ textAlign: "right", mr: 1 }}
                variant="contained"
                onClick={CreateOrderWithOutOnlineAccess}
              >
                Submit Order
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import breadcrumbsbanner from "../../Images/centralturbos/breadcrumbs-common-banner.png";
import florida from "../../Images/centralturbos/florida-map.jpg";
import virginia from "../../Images/centralturbos/virginia-map.jpg";
import northcarolina from "../../Images/centralturbos/north-carolina-map.jpg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IgetContactInfo,
  IgetContactShoworHide,
  IgetWHDetails,
} from "../../Types/MicrositeType";
import { Get_Data, GetauthHeader, GeteMailRes } from "../../API/GetDataFromApi";
import { CheckValidEmailAddress } from "../../API/GetMicrositeSettings";

export default function CentralTurbosContact() {
  const [checkVal, setCheckVal] = useState("0");
  const [error, setError] = useState<string | null>(null);
  const [nameVal, setnameVal] = useState("");
  const [emailVal, setemailVal] = useState("");
  const [subjectVal, setsubjectVal] = useState("");
  const [msgVal, setmsgVal] = useState("");

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const authHeader = GetauthHeader();

  const [data, setData1] = useState<IgetContactShoworHide[]>([]);
  const [data1, setData2] = useState<IgetContactInfo[]>([]);
  const [data3, setData4] = useState<IgetWHDetails[]>([]);

  const [latVal, setLatVal] = useState(0);
  const [langVal, setLangVal] = useState(0);
  const [whAddress, setWhAddress] = useState("");
  const [whStateCityCountry, setWhStateCityCountry] = useState("");
  const [whPhoneNo, setWhPhoneNo] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const setValue_Name = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setnameVal(event.target.value);
  };
  const setValue_Email = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setemailVal(event.target.value);
    validateEmail();
  };
  const setValue_Subject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setsubjectVal(event.target.value);
  };
  const setValue_Msg = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setmsgVal(event.target.value);
  };
  const validateEmail = () => {
    const result: boolean = CheckValidEmailAddress(emailVal);
    if (!result) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }
  };
  const Sendmessage = async () => {
    let err_msg = "";
    let success = 0;
    let response: any = null;
    if (nameVal === null || nameVal === "") {
      err_msg = "Enter Name";
    }
    if (subjectVal === null || subjectVal === "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Subject";
    }
    if (msgVal === null || msgVal === "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Message";
    }
    if (error !== null && error !== "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + error.toString();
    } else if (error === null) {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Email Address";
    }
    if (err_msg !== "") {
      alert(err_msg);
    } else {
      var raw = {
        Name: nameVal,
        Email: emailVal,
        Subject: subjectVal,
        Message: msgVal,
      };
      await GeteMailRes(
        EnvData?.REACT_APP_APIURL + "/api/Email/send-email",
        JSON.stringify(raw)
      )
        .then((response: any) => {
          return response;
        })
        .then((results: any) => {
          success = 1;
          alert("Thanks for your message!  We’ll be in touch soon.");
        })
        .catch((error: any) => {});
      if (success === 1) {
        setnameVal("");
        setemailVal("");
        setsubjectVal("");
        setmsgVal("");
        validateEmail();
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      Get_Data(EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-contact", "")
        .then((results?) => {
          setData1(results.data.micrositeContact_ShoworNotResponse);
          setData2(results.data.micrositeContact_CompanyResponse);
          setData4(results.data.micrositeContact_AddressResponse);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, [checkVal]);
  let warehouseContact = false;
  let MainContact = false;
  let MainContactMap = false;
  let MainContactMap_new = false;
  let DropALine = false;
  let ShowOperationalHrs = false;
  let MainContact_md = 4,
    DropALine_md = 7;

  let lat = 0,
    lang = 0;
  let wh_address = "",
    wh_statecitycountry = "",
    wh_phoneno = "",
    totalRows = "";

  {
    data &&
      data.map(
        (elem, index) => (
          (warehouseContact = elem.warehouseContact),
          (MainContact = elem.mainContact),
          (MainContactMap_new = elem.mainContactMap),
          (DropALine = elem.dropALine),
          (ShowOperationalHrs = elem.showOperationalHrs)
        )
      );
  }

  {
    if (data3.length > 0) {
      lat = Number(data3[0].latitude);
      lang = Number(data3[0].longitude);
      wh_address =
        data3[0].warehouseAddress1 + ", " + data3[0].warehouseAddress2;
      wh_statecitycountry =
        data3[0].stateCode +
        ", " +
        data3[0].cityName +
        ", " +
        data3[0].countryName;
      wh_phoneno = data3[0].phonePrefix + "" + data3[0].warehousePhone;
      totalRows = data3[0].totalRows;
    }
  }

  if (ShowOperationalHrs === false) {
    MainContact_md = 6;
    DropALine_md = 6;
  }

  const updateValue = (target: any) => {
    let latlang = target.target.value;
    lat = 0;
    lang = 0;
    let addresswh = "",
      statecitycountrywh = "";
    setLatVal(0);
    setLangVal(0);
    setWhAddress("");
    setWhStateCityCountry("");
    setWhPhoneNo("");
    latlang.split("###").map((item: any, index: number) => {
      if (index === 0) {
        setLatVal(Number(item));
      } else if (index === 1) {
        setLangVal(Number(item));
      } else if (index === 2) {
        addresswh = item;
      } else if (index === 3) {
        addresswh = addresswh + ", " + item;
        setWhAddress(addresswh);
      } else if (index === 4) {
        statecitycountrywh = item;
      } else if (index === 5) {
        statecitycountrywh = statecitycountrywh + ", " + item;
      } else if (index === 6) {
        statecitycountrywh = statecitycountrywh + ", " + item;
        setWhStateCityCountry(statecitycountrywh);
      } else if (index === 7) {
        setWhPhoneNo(item);
      }
    });
  };
  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          mt: 0,
          mb: 1,
          p: 7,
          backgroundImage: `url(${breadcrumbsbanner})`,
        }}
        className="central-turbos-breadcrumbs-banner"
      >
        <Container>
          <Box sx={{ px: 5 }}>
            <Typography className="central-turbos-breadcrumbs-title">
              Contact
            </Typography>
            {/* <Typography className="central-turbos-breadcrumbs-sub-title">subhead</Typography> */}
          </Box>
        </Container>
      </Paper>
      <Paper elevation={0} sx={{ mt: 1, mb: 2, py: 3 }}>
        <Container>
          {MainContactMap_new ? (
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box className="central-turbos-contact-adderss-box">
                  <Box>
                    <img src={data3[0]?.imgURL} alt="" />
                  </Box>
                  <Stack sx={{ mt: 1 }}>
                    <Typography className="central-turbos-title">
                      {data3[0]?.stateName}
                    </Typography>
                    <Typography
                      className="central-turbos-contact-adderss"
                      variant="subtitle2"
                    >
                      {data3[0]?.address}
                    </Typography>
                    <Typography className="central-turbos-contact-adderss">
                      {data3[0]?.cityName}, {data3[0]?.stateCode},{" "}
                      {data3[0]?.zipCode}
                    </Typography>
                    <Typography className="central-turbos-contact-adderss">
                      Phone: {data3[0]?.phonePrefix} {data3[0]?.warehousePhone}{" "}
                    </Typography>
                    {data3[0]?.faxnumber !== "" ? (
                      <Typography className="central-turbos-contact-adderss">
                        Fax: {data3[0]?.faxnumber}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>

                  {data3[0]?.workhrs_Content1 != "" ? (
                    <>
                      <Box mt={1}>
                        <Typography
                          className="central-turbos-contact-adderss"
                          variant="subtitle2"
                          mb={1}
                        >
                          OPERATIONAL HOURS
                        </Typography>
                        <Typography className="central-turbos-contact-adderss">
                          {data3[0]?.workhrs_Content1}
                        </Typography>
                        {data3[0]?.workhrs_Content2 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[0]?.workhrs_Content2}
                            </Typography>
                          </>
                        ) : null}
                        {data3[0]?.workhrs_Content3 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[0]?.workhrs_Content3}
                            </Typography>
                          </>
                        ) : null}
                        {data3[0]?.workhrs_Content4 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[0]?.workhrs_Content4}
                            </Typography>
                          </>
                        ) : null}
                        {data3[0]?.workhrs_Content5 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[0]?.workhrs_Content5}
                            </Typography>
                          </>
                        ) : null}
                        {data3[0]?.workhrs_Content6 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[0]?.workhrs_Content6}
                            </Typography>
                          </>
                        ) : null}
                        {data3[0]?.workhrs_Content7 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[0]?.workhrs_Content7}
                            </Typography>
                          </>
                        ) : null}
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className="central-turbos-contact-adderss-box">
                  <Box>
                    <img src={data3[1]?.imgURL} alt="" />
                  </Box>
                  <Stack sx={{ mt: 1 }}>
                    <Typography className="central-turbos-title">
                      {data3[1]?.stateName}
                    </Typography>
                    <Typography
                      className="central-turbos-contact-adderss"
                      variant="subtitle2"
                    >
                      {data3[1]?.address}
                    </Typography>
                    <Typography className="central-turbos-contact-adderss">
                      {data3[1]?.cityName}, {data3[1]?.stateCode},{" "}
                      {data3[1]?.zipCode}
                    </Typography>
                    <Typography className="central-turbos-contact-adderss">
                      Phone: {data3[1]?.phonePrefix} {data3[0]?.warehousePhone}{" "}
                    </Typography>
                    {data3[1]?.faxnumber !== "" ? (
                      <Typography className="central-turbos-contact-adderss">
                        Fax: {data3[1]?.faxnumber}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>

                  {data3[1]?.workhrs_Content1 != "" ? (
                    <>
                      <Box mt={1}>
                        <Typography
                          className="central-turbos-contact-adderss"
                          variant="subtitle2"
                          mb={1}
                        >
                          OPERATIONAL HOURS
                        </Typography>
                        <Typography className="central-turbos-contact-adderss">
                          {data3[1]?.workhrs_Content1}
                        </Typography>
                        {data3[1]?.workhrs_Content2 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[1]?.workhrs_Content2}
                            </Typography>
                          </>
                        ) : null}
                        {data3[1]?.workhrs_Content3 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[1]?.workhrs_Content3}
                            </Typography>
                          </>
                        ) : null}
                        {data3[1]?.workhrs_Content4 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[1]?.workhrs_Content4}
                            </Typography>
                          </>
                        ) : null}
                        {data3[1]?.workhrs_Content5 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[1]?.workhrs_Content5}
                            </Typography>
                          </>
                        ) : null}
                        {data3[1]?.workhrs_Content6 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[1]?.workhrs_Content6}
                            </Typography>
                          </>
                        ) : null}
                        {data3[1]?.workhrs_Content7 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[1]?.workhrs_Content7}
                            </Typography>
                          </>
                        ) : null}
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Grid>{" "}
              <Grid item xs={12} md={4}>
                <Box className="central-turbos-contact-adderss-box">
                  <Box>
                    <img src={data3[2]?.imgURL} alt="" />
                  </Box>
                  <Stack sx={{ mt: 1 }}>
                    <Typography className="central-turbos-title">
                      {data3[2]?.stateName}
                    </Typography>
                    <Typography
                      className="central-turbos-contact-adderss"
                      variant="subtitle2"
                    >
                      {data3[2]?.address}
                    </Typography>
                    <Typography className="central-turbos-contact-adderss">
                      {data3[2]?.cityName}, {data3[2]?.stateCode},{" "}
                      {data3[2]?.zipCode}
                    </Typography>
                    <Typography className="central-turbos-contact-adderss">
                      Phone: {data3[2]?.phonePrefix} {data3[2]?.warehousePhone}{" "}
                    </Typography>
                    {data3[2]?.faxnumber !== "" ? (
                      <Typography className="central-turbos-contact-adderss">
                        Fax: {data3[2]?.faxnumber}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>

                  {data3[2]?.workhrs_Content1 != "" ? (
                    <>
                      <Box mt={1}>
                        <Typography
                          className="central-turbos-contact-adderss"
                          variant="subtitle2"
                          mb={1}
                        >
                          OPERATIONAL HOURS
                        </Typography>
                        <Typography className="central-turbos-contact-adderss">
                          {data3[2]?.workhrs_Content1}
                        </Typography>
                        {data3[2]?.workhrs_Content2 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[2]?.workhrs_Content2}
                            </Typography>
                          </>
                        ) : null}
                        {data3[2]?.workhrs_Content3 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[2]?.workhrs_Content3}
                            </Typography>
                          </>
                        ) : null}
                        {data3[2]?.workhrs_Content4 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[2]?.workhrs_Content4}
                            </Typography>
                          </>
                        ) : null}
                        {data3[2]?.workhrs_Content5 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[2]?.workhrs_Content5}
                            </Typography>
                          </>
                        ) : null}
                        {data3[2]?.workhrs_Content6 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[2]?.workhrs_Content6}
                            </Typography>
                          </>
                        ) : null}
                        {data3[2]?.workhrs_Content7 != "" ? (
                          <>
                            <Typography className="central-turbos-contact-adderss">
                              {data3[2]?.workhrs_Content7}
                            </Typography>
                          </>
                        ) : null}
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Divider sx={{ mt: 2 }} />
          {MainContact ? (
            <Box className="central-turbos-contact-form-div">
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Box mb={2} p={2}>
                    <Typography className="central-turbos-title">
                      SALES INQUIRES
                    </Typography>
                    <Typography>{data1[0]?.email}</Typography>
                  </Box>
                  <Box mb={2} p={2}>
                    <Typography className="central-turbos-title">
                      MARKETING
                    </Typography>
                    <Typography>marketing@centralturbos.com</Typography>
                  </Box>
                  <Box mb={2} p={2}>
                    <Typography>-</Typography>
                    <Typography>All other questions,</Typography>
                    <Typography>please use the form on the right</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Box mb={2} p={2} className="central-turbos-contact-form">
                    <FormControl sx={{ width: "100%", mb: 2 }}>
                      <Typography sx={{ mb: 1 }}>Name (required)</Typography>
                      <TextField
                        fullWidth
                        //placeholder="Name"
                        variant="standard"
                        //  label="Name"
                        value={nameVal}
                        onChange={(event) => setValue_Name(event)}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 2 }}>
                      <Typography sx={{ mb: 1 }}>
                        Email Address (required)
                      </Typography>
                      <TextField
                        fullWidth
                        //placeholder="Email"
                        variant="standard"
                        //    label="Email"
                        value={emailVal}
                        onChange={(event) => setValue_Email(event)}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 2 }}>
                      <Typography sx={{ mb: 1 }}>Subject (required)</Typography>
                      <TextField
                        fullWidth
                        //placeholder="Subject"
                        variant="standard"
                        //  label="Subject"
                        value={subjectVal}
                        onChange={(event) => setValue_Subject(event)}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 2 }}>
                      <Typography sx={{ mb: 1 }}>Message (required)</Typography>
                      <TextField
                        fullWidth
                        //placeholder="Your Message"
                        multiline
                        variant="standard"
                        //      label="Your Message"
                        rows={3}
                        value={msgVal}
                        onChange={(event) => setValue_Msg(event)}
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      sx={{ float: "right" }}
                      onClick={() => Sendmessage()}
                    >
                      Send Message
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <></>
          )}
        </Container>
      </Paper>
    </div>
  );
}

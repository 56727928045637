import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import initialState from "./Initialstate";
import {
  categoryfilter,
  brandFilter,
  descriptionFilter,
  extenderBrand,
  extenderCrossReference,
  searchtext,
  VehicleFilterYear,
  VehicleFilterMake,
  VehicleFilterModel,
  VehicleFilterEngine,
  AttributeFilter,
  ApplicationFilter,
  AttributeFilterWithValue,
  ApplicationFilterWithvalue,
  FromToValues,
  productdataarr,
  MsSettings,
  TriggerRest,
  extrafilters,
  partdetailsInfo,
  ordersummary,
  orderHistory,
  FreightCount,
  Env_Settings,
  CaptchaValue,
} from "./Partsearchfilter";
import { Counterstate, Userstate } from "./Initialstate";

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addfooterEmaildata: (state, action) => {
      state.data = action.payload[0];
    },
  },
});

export const usertoken = createSlice({
  name: "todo",
  initialState: { usertoken: false },
  reducers: {
    addtoken: (state) => {
      state.usertoken = true;
    },
  },
});

export const pscategoryFilter = createSlice({
  name: "PSCategoryFilter",
  initialState: categoryfilter,
  reducers: {
    addCategoryfilter: (state, action) => {
      state.categoryId = action.payload.categoryId;
      state.categoryName = action.payload.categoryName;
    },
  },
});

export const psbrandFilter = createSlice({
  name: "PSBrandFilter",
  initialState: brandFilter,
  reducers: {
    addBrandfilter: (state, action) => {
      state.brandId = action.payload.brandId;
      state.brandName = action.payload.brandName;
    },
  },
});

export const psDescsearchbrandFilter = createSlice({
  name: "PSDescriptionFilter",
  initialState: descriptionFilter,
  reducers: {
    addDesfilter: (state, action) => {
      state.descriptionId = action.payload.descriptionId;
      state.desDescriptionName = action.payload.desDescriptionName;
    },
  },
});

export const extenderBrandFilter = createSlice({
  name: "extenderBrandFilter",
  initialState: extenderBrand,
  reducers: {
    addExBrandfilter: (state, action) => {
      state.brandId = action.payload.brandId;
      state.brandName = action.payload.brandName.trim().replace(/\s+/g, "");
    },
  },
});

export const extenderCrossReferenceFilter = createSlice({
  name: "extenderCrossReferenceFilter",
  initialState: extenderCrossReference,
  reducers: {
    addExCrossReffilter: (state, action) => {
      // console.log(action.payload.CompetitorpartNumber.trim().replace(/\s+/g, ''), "action from extenderCrossReferenceFilter redux 1");

      state.CompetitorId = action.payload.CompetitorId;
      state.CompetitorpartNumber = action.payload.CompetitorpartNumber;
    },
  },
});

export const extenderTextCapture = createSlice({
  name: "extenderTextCapture",
  initialState: searchtext,
  reducers: {
    addSearchtext: (state, action) => {
      state.Text = action.payload;
    },
  },
});

export const vehicleyearCapture = createSlice({
  name: "vehicleYearCapture",
  initialState: VehicleFilterYear,
  reducers: {
    addVSyear: (state, action) => {
      state.YearId = action.payload.YearId;
      state.YearName = action.payload.YearName;
    },
  },
});

export const vehiclemakeCapture = createSlice({
  name: "vehicleMakeCapture",
  initialState: VehicleFilterMake,
  reducers: {
    addVSmake: (state, action) => {
      state.MakeId = action.payload.MakeId;
      state.MakeName = action.payload.MakeName;
    },
  },
});

export const vehiclemodelCapture = createSlice({
  name: "vehicleModelCapture",
  initialState: VehicleFilterModel,
  reducers: {
    addVSmodel: (state, action) => {
      state.ModelId = action.payload.ModelId;
      state.ModelName = action.payload.ModelName;
    },
  },
});

export const vehicleengineCapture = createSlice({
  name: "vehicleEngineCapture",
  initialState: VehicleFilterEngine,
  reducers: {
    addVSengine: (state, action) => {
      state.EngineId = action.payload.EngineId;
      state.EngineName = action.payload.EngineName;
    },
  },
});

export const attributeFilters = createSlice({
  name: "attributeFilters",
  initialState: AttributeFilter,
  reducers: {
    addAttribute: (state, action) => {
      state.Attribute = action.payload.Attribute;
    },
  },
});

export const triggerrestfunction = createSlice({
  name: "triggerrestfunction",
  initialState: { Trigger: false },
  reducers: {
    trigger: (state) => {
      state.Trigger = !state.Trigger;
    },
  },
});

export const applicationFilters = createSlice({
  name: "applicationFilters",
  initialState: ApplicationFilter,
  reducers: {
    addApplication: (state, action) => {
      state.Application = action.payload.Application;
    },
  },
});

export const attributeFiltersWithValue = createSlice({
  name: "attributeFiltersWithValue",
  initialState: AttributeFilterWithValue,
  reducers: {
    addAttributeWithValue: (state, action) => {
      const found = state.items.some(
        (element: any) => element.AttributeName == action.payload.AttributeName
      );
      if (found !== true) {
        state.items.push(action.payload);
      } else {
        state.items = state.items.filter(
          (item: any) => item.AttributeName !== action.payload.AttributeName
        );
        state.items.push(action.payload);
      }
    },
    removeAttributeWithValue: (state = initialState, action) => {
      const id = action.payload;

      state.items = state.items.filter((item: any) => item.Attributeid !== id);
    },
    clearallAttributeWithValue: (state = initialState) => {
      state.items = [];
    },
  },
});

export const applicationFiltersWithValue = createSlice({
  name: "applicationFiltersWithValue",
  initialState: ApplicationFilterWithvalue,
  reducers: {
    addApplicationWithValue: (state, action) => {
      // state.ApplicationName = action.payload.ApplicationName;
      // state.ApplicationValue = action.payload.ApplicationValue;

      const found = state.items.some(
        (element: any) =>
          element.ApplicationName == action.payload.ApplicationName
      );
      if (found !== true) {
        state.items.push(action.payload);
      } else {
        state.items = state.items.filter(
          (item: any) => item.ApplicationName !== action.payload.ApplicationName
        );
        state.items.push(action.payload);
      }
    },
    removeApplicationWithValue: (state = initialState, action) => {
      const id = action.payload;

      state.items = state.items.filter(
        (item: any) => item.Applicationid !== id
      );
    },
    clearalllApplicationWithValue: (state = initialState) => {
      state.items = [];
    },
  },
});

export const FromTofilter = createSlice({
  name: "FromTofilter",
  initialState: FromToValues,
  reducers: {
    addfromto: (state, action) => {
      // state.Fromvalue = action.payload.fromval;
      // state.Tovalue = action.payload.toval;

      state.items.push(action.payload);
    },
  },
});

export const productlist = createSlice({
  name: "productlist",
  initialState: productdataarr,
  reducers: {
    addproduct: (state, action) => {
      state[0] = action.payload;
    },
  },
});

export const msSettingsCapture = createSlice({
  name: "msSettingsCapture",
  initialState: MsSettings,
  reducers: {
    addMsSettings: (state, action) => {
      console.log("action", action);
      console.log("action", action.payload.captchaRequired);
      state.banner = action.payload.EngineId;
      state.topCategory = action.payload.topCategory;
      state.topProducts = action.payload.topProducts;
      state.popularBrands = action.payload.popularBrands;
      state.aboutUs = action.payload.aboutUs;
      state.video = action.payload.video;
      state.testimonial = action.payload.testimonial;
      state.micrositeName = action.payload.micrositeName;
      state.freeshipping = action.payload.freeshipping;
      state.tollFree = action.payload.tollFree;
      state.shoppingcart = action.payload.shoppingcart;
      state.freeshippingValue = action.payload.freeshippingValue;
      state.tollFreeNumber = action.payload.tollFreeNumber;
      state.policiesandTerms = action.payload.policiesandTerms;
      state.cartCount = action.payload.cartCount;
      state.logo = action.payload.logo;
      state.search = action.payload.search;
      state.newsLetter = action.payload.newsLetter;
      state.dealerLocation = action.payload.dealerLocation;
      state.services = action.payload.services;
      state.Product = action.payload.Product;
      state.showinFooter = action.payload.showinFooter;
      state.faq = action.payload.faq;
      state.googleAnalyticsCode = action.payload.googleAnalyticsCode;
      state.BingWebmasterCode = action.payload.BingWebmasterCode;
      state.partsSearchMenu = action.payload.partsSearchMenu;
      state.loginOption = action.payload.loginOption;
      state.catalogueWithPricing = action.payload.catalogueWithPricing;
      state.contactEmail = action.payload.contactEmail;
      state.captchaRequired = action.payload.captchaRequired;
    },
  },
});

export const Extrafilters = createSlice({
  name: "Extrafilters",
  initialState: extrafilters,
  reducers: {
    addAttributesearch: (state, action) => {
      state.Attributesearch = action.payload;
    },
    clearAttributesearch: (state, action) => {
      state.Attributesearch = [];
    },
    addDisplayText: (state, action) => {
      // console.log("Display Text", action.payload);
      state.DisplayText = action.payload;
    },
    clearDisplayText: (state) => {
      state.DisplayText = "";
    },

    // Application fitlters
    addApplicationfilters: (state, action) => {
      state.ApplicationFilters = action.payload;
    },
    ClearApplicationfilters: (state, action) => {
      state.ApplicationFilters = [];
    },
    // Attribute filters
    AddAttributefilters: (state, action) => {
      state.Attributfilters = action.payload;
    },
    clearattributefilters: (state, action) => {
      state.Attributfilters = [];
    },

    addAttributes: (state, action) => {
      state.Attribute = action.payload;
    },
    clearAttributes: (state, action) => {
      state.Attribute = "";
    },
    addapplication: (state, action) => {
      state.Application = action.payload;
    },
    clearApplication: (state, action) => {
      state.Application = "";
    },

    addCategorySelection: (state, action) => {
      state.CategorySelection = action.payload;
    },
    clearCategorySelection: (state) => {
      state.CategorySelection = true;
    },
    addBrandSelection: (state, action) => {
      state.BrandSelection = action.payload;
    },
    clearBrandSelection: (state) => {
      state.BrandSelection = true;
    },
    addDescriptionSelection: (state, action) => {
      state.DescriptionSelection = action.payload;
    },
    clearDescriptionSelection: (state) => {
      state.DescriptionSelection = true;
    },

    addMakeSelection: (state, action) => {
      state.MakeSelection = action.payload;
    },
    clearMakeSelection: (state) => {
      state.MakeSelection = true;
    },

    addModelSelection: (state, action) => {
      state.ModelSelection = action.payload;
    },
    clearModelSelection: (state) => {
      state.ModelSelection = true;
    },

    addYearSelection: (state, action) => {
      state.YearSelection = action.payload;
    },
    clearYearSelection: (state) => {
      state.YearSelection = true;
    },

    addEngineSelection: (state, action) => {
      state.EngineSelection = action.payload;
    },
    clearEngineSelection: (state) => {
      state.EngineSelection = true;
    },

    addVehicleSearchFilterExists: (state, action) => {
      state.VehicleSearchFilterExists = action.payload;
    },
    clearVehicleSearchFilterExists: (state) => {
      state.VehicleSearchFilterExists = true;
    },

    addPartStatusFilter: (state, action) => {
      state.PartStatus = action.payload;
    },
    clearPartStatusFilter: (state) => {
      state.PartStatus = "";
    },
  },
});

export const PartdetailsData = createSlice({
  name: "PartdetailsData",
  initialState: partdetailsInfo,
  reducers: {
    addPDInfo: (state, action) => {
      // console.log(action.payload, "from details");
      state.partnumber.push(action.payload.partnumber);
      state.partflag.push(action.payload.partflag);
    },
    addprevPart: (state, action) => {
      state.prevPart = action.payload;
    },

    clearPrevPart: (state) => {
      state.prevPart = "";
    },
    clearPDInfo: (state) => {
      state.partnumber.pop();
      state.partflag.pop();
    },
  },
});

export const OrdersummaryDetails = createSlice({
  name: "OrdersummaryDetails",
  initialState: ordersummary,
  reducers: {
    addSubtotal: (state, action) => {
      // console.log("SubTotal", action);
      state.Subtotal = action.payload;
    },
  },
});

export const OrderHistoryCapture = createSlice({
  name: "OrderHistoryCapture",
  initialState: orderHistory,
  reducers: {
    addOrderHistoryDetails: (state, action) => {
      // console.log("SubTotal", action);
      state.OrderId = action.payload.Id;
      state.OrderMail = action.payload.mail;
    },
  },
});

export const SetFreightCount = createSlice({
  name: "SetFreightCount",
  initialState: FreightCount,
  reducers: {
    addFreightCount: (state, action) => {
      state.FreightCount = action.payload;
    },
  },
});

export const SetCaptchaValue = createSlice({
  name: "SetCaptchaValue",
  initialState: CaptchaValue,
  reducers: {
    addCaptchaValue: (state, action) => {
      state.CaptchaValue = action.payload;
    },
  },
});

export const PayPalGenerated = createSlice({
  name: "PayPalGenerated",
  initialState: {
    PaypalId: "",
  },
  reducers: {
    TriggerPaypalGenerated: (state, action) => {
      state.PaypalId = action.payload;
    },
  },
});

export const SetEnvSettingd = createSlice({
  name: "SetEnvSettingd",
  initialState: Env_Settings,
  reducers: {
    addEnvSettings: (state, action) => {
      state.REACT_APP_MICROSITENAME = action.payload.REACT_APP_MICROSITENAME;
      state.REACT_APP_MICROSITEID = action.payload.REACT_APP_MICROSITEID;
      state.REACT_APP_PAYFLOW_HOST = action.payload.REACT_APP_PAYFLOW_HOST;
      state.REACT_APP_PAYFLOW_HOST_ONETIME =
        action.payload.REACT_APP_PAYFLOW_HOST_ONETIME;
      state.REACT_APP_PAYFLOW_HOST_MODE =
        action.payload.REACT_APP_PAYFLOW_HOST_MODE;
      state.REACT_APP_SHOWSERVICEMENU =
        action.payload.REACT_APP_SHOWSERVICEMENU;
      state.REACT_APP_STATICPAGE = action.payload.REACT_APP_STATICPAGE;
      state.REACT_APP_PAYPALURL = action.payload.REACT_APP_PAYPALURL;
      state.REACT_APP_CARDPOINTEURL = action.payload.REACT_APP_CARDPOINTEURL;
      state.REACT_APP_CARDPOINTEIFRAMEURL =
        action.payload.REACT_APP_CARDPOINTEIFRAMEURL;
      state.REACT_APP_APIURL = action.payload.REACT_APP_APIURL;
      state.REACT_APP_CDNURL = action.payload.REACT_APP_CDNURL;
      state.REACT_APP_NOIMAGEURL = action.payload.REACT_APP_NOIMAGEURL;
      state.REACT_APP_USERNAME = action.payload.REACT_APP_USERNAME;
      state.REACT_APP_PASSWORD = action.payload.REACT_APP_PASSWORD;
      state.REACT_APP_APIKEY = action.payload.REACT_APP_APIKEY;
      state.REACT_APP_APIREQUESTKEY = action.payload.REACT_APP_APIREQUESTKEY;
      state.REACT_APP_SHOWMULTILANGUAGE =
        action.payload.REACT_APP_SHOWMULTILANGUAGE;
      state.REACT_APP_CADENCELOGINURL =
        action.payload.REACT_APP_CADENCELOGINURL;
      state.REACT_APP_CADENCENEWACCOUNTURL =
        action.payload.REACT_APP_CADENCENEWACCOUNTURL;
      state.REACT_APP_STATIC_PAGE = action.payload.REACT_APP_STATIC_PAGE;

      state.REACT_APP_MULTILANGUAGE = action.payload.REACT_APP_MULTILANGUAGE;
      state.REACT_APP_SHOWCATALOG = action.payload.REACT_APP_SHOWCATALOG;
      state.REACT_APP_SHOW_CENTRAL_TURBO = action.payload.REACT_APP_SHOW_CENTRAL_TURBO;
      state.REACT_APP_RECAPTCHA_SITEKEY=action.payload.REACT_APP_RECAPTCHA_SITEKEY;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addfooterEmaildata } = counterSlice.actions;
export const { addtoken } = usertoken.actions;

export const { addCategoryfilter } = pscategoryFilter.actions;
export const { addBrandfilter } = psbrandFilter.actions;
export const { addDesfilter } = psDescsearchbrandFilter.actions;

export const { addExBrandfilter } = extenderBrandFilter.actions;
export const { addExCrossReffilter } = extenderCrossReferenceFilter.actions;

export const { addSearchtext } = extenderTextCapture.actions;

export const { addVSyear } = vehicleyearCapture.actions;
export const { addVSmake } = vehiclemakeCapture.actions;
export const { addVSmodel } = vehiclemodelCapture.actions;
export const { addVSengine } = vehicleengineCapture.actions;

export const { addAttribute } = attributeFilters.actions;
export const { addApplication } = applicationFilters.actions;
export const { TriggerPaypalGenerated } = PayPalGenerated.actions;

export const {
  addAttributeWithValue,
  removeAttributeWithValue,
  clearallAttributeWithValue,
} = attributeFiltersWithValue.actions;
export const {
  addApplicationWithValue,
  removeApplicationWithValue,
  clearalllApplicationWithValue,
} = applicationFiltersWithValue.actions;
export const { addfromto } = FromTofilter.actions;
export const { addproduct } = productlist.actions;
export const { addMsSettings } = msSettingsCapture.actions;
// export const { triggerwrest} =triggerrestfunction.actions;
export const { trigger } = triggerrestfunction.actions;
export const {
  addAttributesearch,
  addDisplayText,
  addApplicationfilters,
  AddAttributefilters,
  clearAttributesearch,
  clearDisplayText,
  ClearApplicationfilters,
  clearattributefilters,
  addAttributes,
  clearAttributes,
  addapplication,
  clearApplication,
  addCategorySelection,
  clearCategorySelection,
  addBrandSelection,
  clearBrandSelection,
  addDescriptionSelection,
  clearDescriptionSelection,
  addMakeSelection,
  clearMakeSelection,
  addModelSelection,
  clearModelSelection,
  addYearSelection,
  clearYearSelection,
  addEngineSelection,
  clearEngineSelection,
  addVehicleSearchFilterExists,
  clearVehicleSearchFilterExists,
  addPartStatusFilter,
  clearPartStatusFilter,
} = Extrafilters.actions;

export const { addPDInfo, clearPDInfo, addprevPart, clearPrevPart } =
  PartdetailsData.actions;

export const { addSubtotal } = OrdersummaryDetails.actions;
export const { addOrderHistoryDetails } = OrderHistoryCapture.actions;
export const { addFreightCount } = SetFreightCount.actions;
export const { addCaptchaValue } = SetCaptchaValue.actions;

export const { addEnvSettings } = SetEnvSettingd.actions;

export const counterReducer = counterSlice.reducer;
export const tokenReducer = usertoken.reducer;

export const PSCategoryFilterreducer = pscategoryFilter.reducer;
export const PSBrandfilterreducer = psbrandFilter.reducer;
export const PSDescfilterreducer = psDescsearchbrandFilter.reducer;

export const extenderBrandFilterreduceer = extenderBrandFilter.reducer;
export const extenderCrossReferenceFilterreducer =
  extenderCrossReferenceFilter.reducer;

export const extnderTextSearch = extenderTextCapture.reducer;

export const VehiclesearchYearselect = vehicleyearCapture.reducer;
export const VehiclesearchMakeselect = vehiclemakeCapture.reducer;
export const VehiclesearchModelselect = vehiclemodelCapture.reducer;
export const VehiclesearchEngineselect = vehicleengineCapture.reducer;

export const applicationFiltersreducer = applicationFilters.reducer;
export const attributeFiltersreducer = attributeFilters.reducer;
export const applicationFiltersWithvaluereducer =
  applicationFiltersWithValue.reducer;
export const attributeFiltersWithvaluereducer =
  attributeFiltersWithValue.reducer;
export const attributeFilterFromto = FromTofilter.reducer;

export const productlistdata = productlist.reducer;
export const ms_Settings = msSettingsCapture.reducer;
export const trigger_rest = triggerrestfunction.reducer;
export const extra_filters = Extrafilters.reducer;
export const PartDetailsInfo = PartdetailsData.reducer;
export const OrderSummaryInfo = OrdersummaryDetails.reducer;
export const OrderHistoryDetailsCapture = OrderHistoryCapture.reducer;
export const CaptureFreightCount = SetFreightCount.reducer;
export const CaptureCaptchaValue = SetCaptchaValue.reducer;

export const EnvSettings = SetEnvSettingd.reducer;
export const Paypalgenerated = PayPalGenerated.reducer;

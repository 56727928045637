import {
  Grid,
  Box,
  Button,
  Divider,
  Menu,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import "./product.style.css";
import { Stack } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { IProductProps } from "../../Types/MicrositeType";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CATEGORY_FILTER, BRAND_FILTER } from "../../Redux/ReduxConstants";
import { useEffect, useState } from "react";
import { GetData, GetDataMultiResults } from "../../API/GetDataFromApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import LinkIcon from "@mui/icons-material/Link";
import Link from "@mui/material/Link";
import {
  addCategoryfilter,
  trigger,
  addCategorySelection,
} from "../../Redux/Counterslice";
import productnote from "../../Images/notes-icons.png";
import Description from "./description";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import eventEmitter from "../PartSearchTest/eventEmmiter";
import { error } from "console";

export const ProductList = (props: {
  count?: number;
  _product: IProductProps[];
  partsearch?: boolean;
  selectcat?: any;
}) => {
  const [Brands, setData] = useState<IProductProps[]>([]);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let url =
    EnvData?.REACT_APP_CDNURL +
    "/Microsite/" +
    EnvData?.REACT_APP_MICROSITENAME +
    "/Category/ProductImage/";
  let run = true;

  //console.log(props._product.length, "productData 11");
  if (props._product.length > 0) {
    run = false;
  }

  var raw = JSON.stringify({
    // micrositeId: process.env.REACT_APP_MICROSITEID,
    partid: 0,
    flag: "Microsite",
    assetstyle: "string",
    compitPartNo: "string",
    rowcount: props.count,
  });

  // const requestOptions = {
  //   method: "POST",
  //   headers: useGetauthHeader(),
  //   body: raw,
  // };
  // const [brands] = useGetData<IProductProps[]>(
  //   process.env.REACT_APP_APIURL + "/api/Microsite/micro_GetProduct",
  //   requestOptions,
  //   [],
  //   run
  // );
  const dispatch = useDispatch();
  useEffect(() => {
    if (run == true) {
      (async () => {
        GetDataMultiResults(
          EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-top-products"
        )
          .then((results?) => {
            //  console.log(results ," res from multi product list ")
            setData(results.Table);
          })
          .catch((err) => {
            console.error(err);
            //   localStorage.removeItem("user");
          });
      })();
    }
  }, []);
  var brand: IProductProps[] = [];
  if (props._product.length > 0) {
    brand = props._product;
    // console.log(JSON.stringify(props._product))
  } else {
    brand = Brands;
    //console.log(Brands);
  }

  function handleCategory(res: any) {
    if (props.partsearch == true) {
      // console.log(res, "resparts");
      let type = {
        type: CATEGORY_FILTER,
        categoryId: res.categoryId,
        categoryName: res.productName,
      };
      dispatch(addCategoryfilter(type));
      dispatch(addCategorySelection(false));

      // props.selectcat();
      eventEmitter.emit("triggerAutoSelectFilter", { type });
      //setTimeout(() => dispatch(trigger()), 2000);
    }
  }

  return (
    <>
      {EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true" ? (
        <Box>
          <Grid container spacing={2}>
            {brand?.map((elem, index) => {
              let partsearchlink = `/Products/${elem.productName}?`;
              return (
                <Grid item xs={12} sm={4} md={3} key={index}>
                  <Link component={RouterLink} to={partsearchlink}>
                    <div className="ct-categorybox">
                      <Typography className="ct-category-inner">
                        <img
                          src={
                            elem.productImage !== ""
                              ? url + elem.productImage
                              : EnvData?.REACT_APP_NOIMAGEUR
                          }
                          alt="category1"
                          loading="lazy"
                        />
                      </Typography>
                      <Typography className="ct-categorytitle" variant="body2">
                        {elem.productName}
                      </Typography>
                    </div>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <>
          {props.partsearch === true && props._product.length > 0 ? (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" component="h6">
                Search by Category
              </Typography>
            </Box>
          ) : null}

          <Grid container spacing={2}>
            {brand?.map((elem, index) => {
              let partsearchlink = `/Products/${elem.productName}?`;
              return (
                <Grid item xs={12} sm={3} md={2} key={index}>
                  <div className="categorybox">
                    {props.partsearch === true ? (
                      <div
                        className="category-inner"
                        onClick={() => handleCategory(elem)}
                      >
                        <Typography className="categoryimage">
                          <img
                            src={
                              elem.productImage !== ""
                                ? url + elem.productImage
                                : EnvData?.REACT_APP_NOIMAGEUR
                            }
                            alt="category1"
                            loading="lazy"
                          />
                        </Typography>
                        <Typography className="categorytitle" variant="body2">
                          {elem.productName}
                        </Typography>
                      </div>
                    ) : (
                      <Link component={RouterLink} to={partsearchlink}>
                        <div
                          className={
                            EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true"
                              ? "ct-categorybox"
                              : "category-inner"
                          }
                        >
                          <Typography
                            className={
                              EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true"
                                ? "ct-category-inner"
                                : "categoryimage"
                            }
                          >
                            <img
                              src={
                                elem.productImage !== ""
                                  ? url + elem.productImage
                                  : EnvData?.REACT_APP_NOIMAGEUR
                              }
                              alt="category1"
                              loading="lazy"
                            />
                          </Typography>
                          <Typography
                            className={
                              EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true"
                                ? "ct-categorytitle"
                                : "categorytitle"
                            }
                            variant="body2"
                          >
                            {elem.productName}
                          </Typography>
                        </div>
                      </Link>
                    )}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export function PartAccessories({ accessoriesdata, triggsetdetails }: any) {
  // const [data, setData] = useState<[]>();
  const [isHovered, setIsHovered] = useState(false);
  let data = accessoriesdata.props;
  const queryString = new URLSearchParams({ data }).toString();
  const selectData = (state: any) => state.MS_SETTINGS_DATA;
  const msSettings = useSelector(selectData);
  function trigger() {
    triggsetdetails();
  }
  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  return (
    <Box>
      {data.map((elem: any, index: any) => {
        let partnum = elem.partNumber;
        let name = elem.partDescriptionName;
        let shortdesc = name.slice(0, 60);
        const replacedStr = name.replace(/ /g, "-").replace(/\//g, "-");
        const queryName = replacedStr.toString();
        const string = `/Category/${partnum}/${queryName}`;

        return (
          <div className="part-accessories-box" key={index} onClick={trigger}>
            <Link component={RouterLink} to={string}>
              {" "}
              <div className="part-accessories-inner">
                <div className="part-accessories-image">
                  <img src={elem.assetUrl} alt="category1" loading="lazy" />
                </div>
                <div className="part-accessories-content">
                  <Typography variant="h2" className="part-accessories-number">
                    {elem.salesPartnumber !== elem.partNumber &&
                    elem.partNumber !== "" &&
                    elem.partNumber !== undefined &&
                    elem.partNumber !== null &&
                    elem.showInventoryPartnumber === true
                      ? elem.partNumber.toUpperCase() +
                        " / " +
                        elem.salesPartnumber.toUpperCase()
                      : elem.salesPartnumber.toUpperCase()}
                    <Description
                      Fulltext={elem.partDescriptionName}
                      slicedtext={shortdesc}
                    />
                  </Typography>
                  {/* <Typography
                    className="part-accessories-title"
                    variant="body2"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  > */}
                  {/* {elem.partDescriptionName} */}
                  {/* {isHovered ? shortdesc : elem.partDescriptionName}
                  </Typography> */}

                  {msSettings.shoppingcart == true ||
                  (CustomerId !== "0" &&
                    msSettings.catalogueWithPricing === true) ? (
                    <>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="stack-mrg-left"
                      >
                        <Typography
                          variant="subtitle2"
                          component="h6"
                          className="part-accessories-price"
                        >
                          ${elem.total ? elem.total.toFixed(2) : "0.00"}
                        </Typography>
                        {elem.total === undefined ||
                        elem.total === "0" ||
                        elem.total === "0.00" ||
                        elem.total === null ||
                        parseFloat(elem.total.toFixed(2)) ===
                          0 ? null : elem.stockInfo == "In Stock" ? (
                          <Typography variant="body2" className="price-ship">
                            <CheckCircleIcon
                              sx={{ fontSize: "14px", marginRight: "5px" }}
                            />
                            In Stock
                          </Typography>
                        ) : elem.showOutOfStock ? (
                          <p className="out-of-stock">
                            <CancelSharpIcon
                              sx={{ fontSize: "14px", marginRight: "5px" }}
                            />
                            Out of Stock
                          </p>
                        ) : null}
                      </Stack>
                    </>
                  ) : null}
                  {elem.corePrice > 0 ? (
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "600", mt: 1 }}
                      >
                        + Core Deposit : {elem.corePrice.toFixed(2)}
                      </Typography>
                    </Box>
                  ) : null}

                  {elem.crossRefNotes !== "" &&
                  elem.crossRefNotes !== undefined &&
                  elem.crossRefNotes !== null ? (
                    <Box className="product_notes">
                      <Tooltip
                        title={"Part Notes: " + elem.crossRefNotes}
                        arrow
                      >
                        <Typography variant="body2">
                          <img src={productnote} alt="notes" />{" "}
                          {elem.crossRefNotes.slice(0, 20)}
                          {elem.crossRefNotes.length > 20 ? "..." : ""}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ) : null}
                </div>
              </div>{" "}
            </Link>
          </div>
        );
      })}
    </Box>
  );
}

export function AlternativeProduct({ alternatedata, triggsetdetails }: any) {
  let data = alternatedata;

  const selectData = (state: any) => state.MS_SETTINGS_DATA;
  const msSettings = useSelector(selectData);

  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  return (
    <Box>
      {data.map((elem: any, index: any) => {
        let partnum = elem.partNumber;
        let name = elem.partDescriptionName;
        let shortdesc = name.slice(0, 60);
        const replacedStr = name.replace(/ /g, "-").replace(/\//g, "-");
        const queryName = replacedStr.toString();
        const string = `/Category/${partnum}/${queryName}`;

        function trigger() {
          triggsetdetails();
        }

        return (
          <div className="part-accessories-box" key={index} onClick={trigger}>
            <Link component={RouterLink} to={string}>
              <div className="part-accessories-inner">
                <div className="part-accessories-image">
                  <img src={elem.assetUrl} alt="category1" loading="lazy" />
                </div>
                <div className="part-accessories-content">
                  <Typography variant="h2" className="part-accessories-number">
                    {elem.salesPartnumber !== elem.partNumber &&
                    elem.partNumber !== "" &&
                    elem.partNumber !== undefined &&
                    elem.partNumber !== null &&
                    elem.showInventoryPartnumber === true
                      ? elem.partNumber.toUpperCase() +
                        " / " +
                        elem.salesPartnumber.toUpperCase()
                      : elem.salesPartnumber.toUpperCase()}
                    <Description
                      Fulltext={elem.partDescriptionName}
                      slicedtext={shortdesc}
                    />
                  </Typography>
                  {/* <Typography
                    className="part-accessories-title"
                    variant="body2"
                  >
                    {elem.partDescriptionName}
                  </Typography> */}

                  {msSettings.shoppingcart == true ||
                  (CustomerId !== "0" &&
                    msSettings.catalogueWithPricing === true) ? (
                    <>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="stack-mrg-left"
                      >
                        <Typography
                          variant="subtitle2"
                          component="h6"
                          className="part-accessories-price"
                        >
                          ${elem.total ? elem.total.toFixed(2) : "0.00"}
                        </Typography>
                        {elem.total === undefined ||
                        elem.total === "0" ||
                        elem.total === "0.00" ||
                        elem.total === null ||
                        parseFloat(elem.total.toFixed(2)) ===
                          0 ? null : elem.stockInfo == "In Stock" ? (
                          <Typography variant="body2" className="price-ship">
                            <CheckCircleIcon
                              sx={{ fontSize: "14px", marginRight: "5px" }}
                            />
                            In Stock
                          </Typography>
                        ) : elem.showOutOfStock === true ? (
                          <p className="out-of-stock">
                            <CancelSharpIcon
                              sx={{ fontSize: "14px", marginRight: "5px" }}
                            />
                            Out of Stock
                          </p>
                        ) : null}
                      </Stack>
                    </>
                  ) : null}
                  {elem.corePrice > 0 ? (
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "600", mt: 1 }}
                      >
                        + Core Deposit : {elem.corePrice.toFixed(2)}
                      </Typography>
                    </Box>
                  ) : null}
                  {elem.interchangeTypes !== "" &&
                  elem.interchangeTypes !== undefined &&
                  elem.interchangeTypes !== null ? (
                    <Tooltip title={elem.crossRefNotes} arrow>
                      <Typography
                        variant="subtitle2"
                        color="error"
                        sx={{ fontSize: "14px" }}
                        className="interchangetypes"
                      >
                        <IconButton color="error" sx={{ py: 0 }}>
                          {elem.interchangeTypes === "Can Use" ? (
                            <InfoOutlinedIcon />
                          ) : elem.interchangeTypes === "Associated Part" ? (
                            <LinkIcon />
                          ) : elem.interchangeTypes === "Direct Replacement" ? (
                            <CachedIcon />
                          ) : (
                            <></>
                          )}
                        </IconButton>
                        {elem.interchangeTypes}
                      </Typography>
                    </Tooltip>
                  ) : null}
                  {elem.partNotes !== "" &&
                  elem.partNotes !== undefined &&
                  elem.partNotes !== null ? (
                    <Box className="product_notes">
                      <Tooltip title={"Part Notes: " + elem.partNotes} arrow>
                        <Typography variant="body2">
                          <img src={productnote} alt="notes" />{" "}
                          {elem.partNotes.slice(0, 25) + "..."}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ) : null}
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </Box>
  );
}
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

import {
  Typography,
  Divider,
  Box,
  TextField,
  Stack,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../Css/login.style.css";
import logo from "../Images/main-logo.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ProductLoader from "../Components/ProductLoader";
import { Get_Data, GetauthHeader } from "../API/GetDataFromApi";
import axios from "axios";
import { useNavigate } from "react-router";
import { restrictedInputs } from "../API/GetMicrositeSettings";
import { GetData } from "../API/GetDataFromApi";
import ErrorIcon from "@mui/icons-material/Error";
import CachedIcon from "@mui/icons-material/Cached";

import { useDispatch, useSelector } from "react-redux";
import { addOrderHistoryDetails } from "../Redux/Counterslice";
import { validateReCaptcha } from "../Components/Captcha/react-simple-captcha";
import { CheckValidEmailAddress } from "../API/GetMicrositeSettings";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const [showPopup, setshowPopup] = useState(false);
  const [showLoginProblemPopup, setshowLoginProblemPopup] = useState(false);
  const [textUserName, settextUserName] = useState("");
  const [textPassWord, settextPassWord] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [textUserNameError, settextUserNameError] = useState("");
  const [textPassWordError, settextPassWordError] = useState("");

  const [ForgotPassEmail, setForgotPassEmail] = useState("");
  const [ForgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [LoginProblemEmail, setLoginProblemEmail] = useState("");
  const [LoginProblemUserDetails, setLoginProblemUserDetails] = useState([]);
  //const [Captcha, setCaptcha] = useState("");
  //const [Captcha1, setCaptcha1] = useState("");
  const [showPhone, setshowPhone] = useState<boolean>(false);
  const [LoginProblemEmailPhone, setLoginProblemEmailPhone] = useState("");

  const [orderNo, setorderNo] = useState("");
  const [emailVal, setemailVal] = useState("");
  const [error, setError] = useState<string | null>(null);
  const currentURL = window.location.href;
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const setValue_orderNo = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let orderNo = restrictedInputs(event.target.value);

    setorderNo(orderNo.toString());
  };
  const setValue_Email = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let email = restrictedInputs(event.target.value);
    const result: boolean = CheckValidEmailAddress(email);
    setemailVal(email.toString());
    if (!result) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }

    // validateEmail();
  };

  function enterPhoneNumber(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let phone = restrictedInputs(event.target.value);
    setLoginProblemEmailPhone(phone.toString());
  }

  function enterForgpotPassEmail(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let mail = restrictedInputs(event.target.value);
    setForgotPassEmail(mail.toString());
  }

  // function enterCaptcha(
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) {
  //   let captchaVal = restrictedInputs(event.target.value);
  //   setCaptcha(captchaVal.toString());
  // }

  // function enterCaptcha1( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
  //   let captchaVal = restrictedInputs(event.target.value);
  //   setCaptcha1(captchaVal.toString())
  // }

  const setValueLoginProblemEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let email = restrictedInputs(event.target.value);
    setLoginProblemEmail(email.toString());
    // validateLoginProblemEmail();
  };
  const setValueForgotPasswordEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let email = restrictedInputs(event.target.value);
    setForgotPasswordEmail(email.toString());
    // validateLoginProblemEmail();
  };
  async function submitLoginProblem(id?: any) {
    // console.log(error, LoginProblemEmail, "LoginProblemEmail");

    if (!captchaValue) {
      alert("Please complete the reCAPTCHA challenge");
      return;
    }

    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/login-problem`;

    let raw = {
      EmailId: LoginProblemEmail,
      UserId: id !== "" ? id : "",
      phoneNumber: LoginProblemEmailPhone !== "" ? LoginProblemEmailPhone : "",
    };
    //  if (validateCaptcha(Captcha) == false) {
    //   alert("Captcha Does Not Match");
    //   setCaptcha("");
    //   loadCaptchaEnginge(4,"white","black","","canv");
    //   return false;
    // }

    const res = await validateReCaptcha(EnvData, captchaValue);
    if (res.success === false) {
      alert(res.message);
      return false;
    }

    if (LoginProblemEmail !== "" && res.success == true) {
      setLoading(true);
      await GetData(URL, raw)
        .then((res) => {
          // console.log(res, "from Login Res");
          if (res.responseMessage == "Invalid User Email") {
            alert("Enter valid Email Id");
            //loadCaptchaEnginge("4","white","black","","canv");
            setLoginProblemEmail("");
            //setCaptcha("");
            if (loginrecaptchaRef.current) {
              setCaptchaValue(null);
              loginrecaptchaRef.current.reset();
            }

            // setshowPhone(true);
            // setLoginProblemUserDetails([]);
          } else if (res.responseMessage == "Mail has been sent.") {
            alert("Mail has been sent.");
            setshowLoginProblemPopup(false);
            setLoginProblemUserDetails([]);
            // setshowPhone(false);
            // setLoginProblemEmailPhone("");
            closeLightbox();
          }

          if (res.loginProblemUserDtl.length !== 0) {
            setLoginProblemUserDetails(res.loginProblemUserDtl);
            setshowPhone(false);
            setLoginProblemEmailPhone("");
            closeLightbox();
          }
        })

        .catch((error) => {
          console.log("Error in " + error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("Enter Email Address");
      setLoginProblemEmail("");
      //loadCaptchaEnginge("4","white","black","","canv");
      if (loginrecaptchaRef.current) {
        setCaptchaValue(null);
        loginrecaptchaRef.current.reset();
      }
    }
  }

  const dispatch = useDispatch();

  async function submitForgotpass() {
    if (ForgotPassEmail !== "" && ForgotPasswordEmail !== "") {
      if (!forgetcaptchaValue) {
        alert("Please complete the reCAPTCHA challenge");
        return false;
      }

      const res = await validateReCaptcha(EnvData, forgetcaptchaValue);
      if (res.success === false) {
        alert(res.message);
        return false;
      }

      //if (validateCaptcha(Captcha1,true,"canv1") == true) {
      let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/forgot-password`;
      let raw = {
        userName: ForgotPassEmail,
        EmailId: ForgotPasswordEmail,
      };
      setLoading(true);
      await GetData(URL, raw)
        .then((res) => {
          // console.log(res, "from Login Res");
          if (forgotrecaptchaRef.current) {
            setforgetcaptchaValue(null);
            forgotrecaptchaRef.current.reset();
          }
          if (
            res.responseMessage ==
            "Invalid User Name / User Name and Email Id Combination Not Exists"
          ) {
            alert("Enter valid username and Email Id");
            //loadCaptchaEnginge("4","white","black","","canv1");
            setForgotPassEmail("");
            setForgotPasswordEmail("");
            //setCaptcha1("");
          }
          if (res.success == false) {
            alert(res.responseMessage);
            // loadCaptchaEnginge(4,"white","black","","canv1");
          } else if (res.success == true) {
            closeLightbox();
            alert(res.responseMessage);
          }
        })
        .catch((error) => {
          console.log("Error in " + error);
        })
        .finally(() => {
          setLoading(false);
        });

      // } else {
      //   alert("Captcha Does Not Match");
      //   setCaptcha1("");
      //   //loadCaptchaEnginge(4,"white","black","","canv1");
      // }
    } else {
      alert("Enter User Name & EmailId");
      setForgotPassEmail("");
      setForgotPasswordEmail("");
      // loadCaptchaEnginge("4","white","black","","canv1");
      if (forgotrecaptchaRef.current) {
        setforgetcaptchaValue(null);
        forgotrecaptchaRef.current.reset();
      }
      //setCaptcha1("");
      //loadCaptchaEnginge(4,"white","black","","canv1");
    }
    // console.log(error, LoginProblemEmail, "LoginProblemEmail");
  }

  // const validateEmail = () => {
  //   const result: boolean = CheckValidEmailAddress(emailVal);
  //   if (!result) {
  //     setError("Please enter a valid email address");
  //   } else {
  //     setError("");
  //   }
  // };

  // const validateLoginProblemEmail = () => {
  //   const expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const result: boolean = expression.test(LoginProblemEmail);
  //   if (!result) {

  //     setError("Please enter a valid email address");
  //   } else {
  //     setError("");
  //   }
  // };

  const TrackOrder = async () => {
    let err_msg = "";
    if (orderNo === null || orderNo === "") {
      err_msg = "Enter Order Number";
    }
    if (error !== null && error !== "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + error.toString();
    } else if (error === null) {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Email Address";
    }
    console.log(err_msg);
    if (err_msg !== "") {
      alert(err_msg);
    } else {
      // localStorage.setItem("orderHistory_OrderNo", orderNo);
      // localStorage.setItem("orderHistory_Email", emailVal);

      let type = {
        Id: orderNo,
        mail: emailVal,
      };
      dispatch(addOrderHistoryDetails(type));

      navigate("/OrderHistory");
    }
  };

  const navigate = useNavigate();

  const $ref = useRef(null);

  const loginrecaptchaRef = useRef<ReCAPTCHA>(null);

  const forgotrecaptchaRef = useRef<ReCAPTCHA>(null);

  const RECAPTCHA_SITE_KEY = EnvData?.REACT_APP_RECAPTCHA_SITEKEY;

  const [captchaValue, setCaptchaValue] = useState(null);
  const [forgetcaptchaValue, setforgetcaptchaValue] = useState(null);

  const handleCaptchaChange = (value: any) => {
    setCaptchaValue(value);
    console.log("Captcha value:", value);
  };

  const handleForgotCaptchaChange = (value: any) => {
    setforgetcaptchaValue(value);
    console.log("Forgot Captcha value:", value);
  };

  const openLightbox = () => {
    // setTimeout(() => loadCaptchaEnginge(4,"white","black","","canv"), 200);
    // setTimeout(() => loadCaptchaEnginge(4,"white","black","","canv1"), 200);

    setshowPopup(true);
  };
  const closeLightbox = () => {
    setLoginProblemEmail("");
    setForgotPassEmail("");
    setForgotPasswordEmail("");
    //setCaptcha("");
    //setCaptcha1("");
    setshowPopup(false);
    setError("");
    if (loginrecaptchaRef.current) {
      setCaptchaValue(null);
      loginrecaptchaRef.current.reset();
    }

    if (forgotrecaptchaRef.current) {
      setforgetcaptchaValue(null);
      forgotrecaptchaRef.current.reset();
    }
  };

  const openLoginProblem = () => {
    setshowLoginProblemPopup(true);
  };
  const closeLoginProblem = () => {
    setshowLoginProblemPopup(false);
    setLoginProblemUserDetails([]);
    setError("");
    setshowPhone(false);
    setLoginProblemEmailPhone("");
  };

  const handletxtUsernameInputChange = (event: any) => {
    var userName = restrictedInputs(event.target.value);
    settextUserName(userName.toString());
  };
  const handletxtPasswordInputChange = (event: any) => {
    let password = restrictedInputs(event.target.value);
    settextPassWord(password.toString());
  };
  const handleClick = () => {
    navigate("/ContactUs");
  };
  const handleButtonClick = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("authToken");

    // console.log("Login1");
    var raw = JSON.stringify({
      userName: textUserName,
      password: textPassWord,
      loginfrom: "MicrositeLogin",
    });

    (async () => {
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: GetauthHeader(),
        body: raw,
      };
      axios
        .post(
          EnvData?.REACT_APP_APIURL +
            "/authentication-api-v1/get-microsite-authentication-token",
          raw,
          { headers: requestOptions.headers }
        )
        .then((results) => {
          // console.log(results, "Login11");
          localStorage.setItem(
            "authToken",
            results.data["authenticationToken"]
          );
          localStorage.setItem("CustomerInfo", JSON.stringify(results.data));
          localStorage.setItem("CustomerId", results.data.customerId);
          localStorage.setItem("Cstbrnid", results.data.cstBrnId);
          localStorage.setItem("UserId", results.data.userId);
          localStorage.setItem("userName", textUserName);
          navigate("/");
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("Unauthorized response:", error.response);
            alert(error.response.data);
          } else {
            console.error("Error:", error);
          }
          localStorage.removeItem("userName");
          localStorage.removeItem("authToken");
        });
      setLoading(false);
    })();
  };

  // const reloadCaptcha = (id:string) => {
  //   loadCaptchaEnginge("4","white","black","",id);
  // };

  return (
    <>
      {loading === true ? <ProductLoader /> : null}
      <div>
        <div className="login-page split-login-page">
          <div className="login-page-card">
            <div className="login-page-card-body">
              <a className="login-page-logo" href="/">
                <img src={localStorage.getItem("logo") || ""} alt="logo" />
              </a>
              <Box className="login-page-sec">
                <div className="login-box">
                  <div className="product-right-border">
                    <Typography variant="h3">
                      Please log in below
                      <Divider
                        sx={{ bgcolor: "primary.main" }}
                        className="title-divider"
                      />
                    </Typography>
                  </div>
                  <Typography variant="body2">Already Registered?</Typography>

                  <Box sx={{ mt: 2 }}>
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                      <TextField
                        value={textUserName}
                        //onChange={handletxtUsernameInputChange}
                        fullWidth
                        label="User Name"
                        variant="standard"
                        onChange={(e) => {
                          if (e.target.value === "") {
                            settextUserNameError("This Field Is Required");
                          } else {
                            settextUserNameError("");
                          }
                          var userName = restrictedInputs(e.target.value);
                          settextUserName(userName.toString());
                        }}
                        error={textUserNameError != ""}
                        helperText={textUserNameError}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                      <TextField
                        value={textPassWord}
                        //onChange={handletxtPasswordInputChange}
                        fullWidth
                        label="Password"
                        variant="standard"
                        type="password"
                        onChange={(e) => {
                          if (e.target.value === "") {
                            settextPassWordError("This Field Is Required");
                          } else {
                            settextPassWordError("");
                          }
                          var password = restrictedInputs(e.target.value);
                          settextPassWord(password.toString());
                        }}
                        error={textPassWordError != ""}
                        helperText={textPassWordError}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ mb: 2, cursor: "pointer" }}
                      className="login-problem-link"
                    >
                      <Typography
                        variant="body2"
                        sx={{ marginLeft: "auto!important" }}
                        onClick={openLightbox}
                      >
                        Login Problem / Forgot Password
                      </Typography>
                      {/* <Typography variant="body2" onClick={openLoginProblem}>
                          Login Problem?
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ marginLeft: "auto!important" }}
                          onClick={openLightbox}
                        >
                          Forgot Your Password?
                        </Typography> */}
                    </Stack>
                    <Button
                      onClick={handleButtonClick}
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3 }}
                    >
                      Login
                    </Button>
                  </Box>
                </div>
                <div className="track-order-online">
                  <div className="product-right-border">
                    <Typography variant="h3">
                      Track Your Order Online
                      <Divider
                        sx={{ bgcolor: "primary.main" }}
                        className="title-divider"
                      />
                    </Typography>
                  </div>
                  <Box sx={{ mt: 2 }}>
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Order Number"
                        variant="standard"
                        value={orderNo}
                        // onChange={(event) => setValue_orderNo(event)}

                        onChange={(event: any) => {
                          const value = event.target.value;

                          if (/[^0-9]/.test(value)) {
                            // alert('Enter valid order ID (should not contain alphabets)');
                          } else {
                            setValue_orderNo(event);
                            // setTimeout(() => dispatch(trigger()), 200);
                          }
                        }}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        variant="standard"
                        value={emailVal}
                        onChange={(event) => setValue_Email(event)}
                      />
                    </Stack>

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3 }}
                      color="secondary"
                      onClick={() => TrackOrder()}
                    >
                      Track My Order
                    </Button>
                  </Box>
                </div>
              </Box>
            </div>
          </div>
        </div>

        {/* new Login Problem / Forgot Password Design*/}

        <Dialog
          onClose={closeLightbox}
          open={showPopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle>
            <div className="product-right-border">
              <Typography variant="h3">
                Login Problems
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>
            </div>

            <IconButton
              className="brawerclosebutton"
              onClick={closeLightbox}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="login-problems-popup">
              <Box className="login-page-sec">
                <div className="login-box">
                  <div className="product-right-border">
                    <Typography variant="subtitle2">Forgot Username</Typography>
                  </div>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Please enter E-mail ID to get your Username
                  </Typography>
                  <Box mt={2}>
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Enter Your Email ID"
                        variant="standard"
                        sx={{ width: "100%" }}
                        error={textPassWordError != ""}
                        helperText={textPassWordError}
                        onChange={(e) => {
                          setValueLoginProblemEmail(e);
                        }}
                        value={LoginProblemEmail}
                      />
                    </Stack>
                    <div className="ReloadCaptcha">
                      {/* <canvas id="canv" width="50" height="15" ref={$ref}></canvas> */}

                      <ReCAPTCHA
                        ref={loginrecaptchaRef}
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                      />
                      {/* <HCaptchaWidget /> */}
                      {/* <Button onClick={((e)=>{reloadCaptcha("canv")})} variant="text">
                            <CachedIcon />
                          </Button> */}
                    </div>
                    {/* <div className="EnterAboveText">
                          <TextField
                            id="user_captcha_input"
                            label="Enter above Captcha Code"
                            variant="standard"
                            onChange={(e) => {
                              enterCaptcha(e);
                            }}
                            value={Captcha}
                          />
                        </div> */}
                    <Button
                      variant="contained"
                      sx={{ mt: 2, float: "right" }}
                      color="primary"
                      onClick={() => submitLoginProblem("")}
                    >
                      Submit
                    </Button>
                  </Box>
                </div>

                <div className="track-order-online">
                  <div className="product-right-border">
                    <Typography variant="subtitle2">Forgot Password</Typography>
                  </div>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Please enter Username or E-mail ID to get your Login Details
                  </Typography>
                  <Box>
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        id="user_captcha_input"
                        label="User Name *"
                        variant="standard"
                        onChange={(e) => {
                          enterForgpotPassEmail(e);
                        }}
                        value={ForgotPassEmail}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        label="Enter Your Email ID"
                        variant="standard"
                        sx={{ width: "100%" }}
                        error={textPassWordError != ""}
                        helperText={textPassWordError}
                        onChange={(e) => {
                          setValueForgotPasswordEmail(e);
                        }}
                        value={ForgotPasswordEmail}
                      />
                    </Stack>
                    <div className="ReloadCaptcha">
                      <ReCAPTCHA
                        ref={forgotrecaptchaRef}
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={handleForgotCaptchaChange}
                      />
                      {/* <canvas id="canv1" width="50" height="15" ref={$ref}></canvas>
                          <Button onClick={((e)=>{reloadCaptcha("canv1")})} variant="text">
                            <CachedIcon />
                          </Button> */}
                    </div>
                    {/* <div className="EnterAboveText">
                            <TextField
                              id="user_captcha_input"
                              label="Enter above Captcha Code"
                              variant="standard"
                              onChange={(e) => {
                                enterCaptcha1(e);
                              }}
                              value={Captcha1}
                            />
                          </div> */}
                    <Button
                      variant="contained"
                      sx={{ mt: 2, float: "right" }}
                      color="primary"
                      onClick={submitForgotpass}
                    >
                      Submit
                    </Button>
                  </Box>
                </div>
              </Box>
            </div>
            <Stack
              className="login-problem-link"
              textAlign="center"
              sx={{ my: 2 }}
            >
              <Typography variant="body1" onClick={handleClick}>
                If you still have any issue, please click here to contact us
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* <Dialog
            onClose={closeLightbox}
            open={showPopup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle>
              Forgot Password
              <IconButton
                className="brawerclosebutton"
                onClick={closeLightbox}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    id="user_captcha_input"
                    label="User Name *"
                    variant="standard"
                    onChange={(e) => {
                      enterForgpotPassEmail(e);
                    }}
                  />
                </Stack>
                <div className="ReloadCaptcha">
                  <canvas id="canv" width="50" height="15" ref={$ref}></canvas>
                  <Button onClick={reloadCaptcha} variant="text">
                    <CachedIcon />
                  </Button>
                </div>
                <div className="EnterAboveText">
                  <TextField
                    id="user_captcha_input"
                    label="Enter above Captcha Code"
                    variant="standard"
                    onChange={(e) => {
                      enterCaptcha(e);
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  sx={{ mt: 2, float: "right" }}
                  color="primary"
                  onClick={submitForgotpass}
                >
                  Submit
                </Button>
              </Box>
            </DialogContent>
          </Dialog> */}

        <Dialog
          onClose={closeLoginProblem}
          open={showLoginProblemPopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>
            Login Problem
            <IconButton
              className="brawerclosebutton"
              onClick={closeLoginProblem}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
              >
                <TextField
                  fullWidth
                  label="Enter User Name or E-Mail Address*"
                  variant="standard"
                  sx={{ width: "350px" }}
                  error={textPassWordError != ""}
                  helperText={textPassWordError}
                  onChange={(e) => {
                    setValueLoginProblemEmail(e);
                  }}
                />
                {showPhone ? (
                  <></>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, float: "right" }}
                      color="primary"
                      onClick={() => submitLoginProblem("")}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Stack>

              {LoginProblemUserDetails.length > 0 ? (
                <>
                  <Typography variant="body2" sx={{ my: 2 }}>
                    There are multiple users associated with this e-mail. Please
                    select the user name you wish to login.
                  </Typography>
                  <div>
                    <table className="cross-ref-table">
                      <thead>
                        <tr>
                          <th>Select User</th>
                          <th>User Name</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LoginProblemUserDetails.map((res: any) => (
                          <tr key={res.userId}>
                            <td>
                              <Checkbox
                                {...label}
                                onClick={() => {
                                  submitLoginProblem(res.userId);
                                }}
                              />
                            </td>
                            <td>{res.userName}</td>
                            <td>{res.firstName}</td>
                            <td>{res.lastName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}

              {showPhone ? (
                <>
                  <Divider />
                  <Paper
                    elevation={0}
                    sx={{
                      padding: "10px 15px",
                      backgroundColor: "#f7f7f7",
                      border: "1px solid #ccc",
                      mt: 2,
                    }}
                  >
                    <Typography variant="subtitle2" color="error">
                      {" "}
                      <ErrorIcon
                        sx={{ verticalAlign: "middle", fontSize: "17px" }}
                      />{" "}
                      Invalid User Name / Email ID
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter Phone Number*"
                      variant="standard"
                      sx={{ width: "350px" }}
                      inputProps={{
                        pattern: "[0-9]*",
                        minLength: 10,
                        maxLength: 11,
                      }}
                      onChange={(e) => {
                        enterPhoneNumber(e);
                      }}
                    />

                    <Button
                      variant="contained"
                      sx={{ mt: 2, float: "right" }}
                      color="primary"
                      onClick={() => submitLoginProblem("")}
                    >
                      Submit
                    </Button>
                  </Paper>
                </>
              ) : (
                <></>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

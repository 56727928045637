import {
  Typography,
  Stack,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  FormHelperText,
  Divider,
} from "@mui/material";
import axios from "axios";
// import { escape, stringify } from "querystring";
import React, { useEffect, useRef, useState } from "react";
import { Get_Data } from "../../API/GetDataFromApi";
import eventEmitter from "../PartSearchTest/eventEmmiter";
import Loader from "../Loader";
import { CheckValidEmailAddress } from "../../API/GetMicrositeSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLocalstorageValues,
  restrictedInputs,
} from "../../API/GetMicrositeSettings";
import { validateReCaptcha } from "../Captcha/react-simple-captcha";
import CachedIcon from "@mui/icons-material/Cached";
import { addCaptchaValue, SetCaptchaValue } from "../../Redux/Counterslice";
import ReCAPTCHA from "react-google-recaptcha";

export type ICountryProps = {
  countryId: number;
  country: string;
};
export type IStateProps = {
  stateId: number;
  state: string;
};
export type ICityProps = {
  cityId: number;
  city: string;
};
export type IZipProps = {
  zipId: number;
  zip: string;
};
const AddressFilling = (props: {
  mode: string;
  mail: string;
  EmailValidation: any;
  setEmailFromPayPal: any;
  emailErr: string;
}) => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [appSuite, setappSuite] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [extenderData, setExtenderData] = useState<[]>([]);
  const [countryData, setcountryData] = useState<ICountryProps[]>([]);
  const [stateData, setStateData] = useState<IStateProps[]>([]);
  const [citydata, setcitydata] = useState<ICityProps[]>([]);
  const [extenderDefaultValue, setextenderDefaultValue] = useState("");
  const [selectedZip, setselectedZip] = useState("");
  const [selectedCountry, setselectedCountry] = useState(0);
  const [selectedState, setselectedState] = useState(0);
  const [selectedCity, setselectedCity] = useState(0);
  const [ZipId, setZipId] = useState(0);
  const [selectedCountryValue, setselectedCountryValue] = useState("");
  const [selectedStateValue, setselectedStateValue] = useState("");
  const [selectedCityValue, setselectedCityValue] = useState("");
  const [defaultCity, setdefaultCity] = useState("");
  const [zipList, setzipList] = useState<any>([]);
  // const [Email, setEmail] = useState(props.mail);

  const [EmailValid, setEmailValid] = useState(false);
  const [Loading, setLoading] = useState(false);

  const [firstNameErr, setfirstNameErr] = useState("");
  const [lastNameErr, setlastNameErr] = useState("");
  const [extenderDefaultValueErr, setextenderDefaultValueErr] = useState("");
  const [selectedZipErr, setselectedZipErr] = useState("");
  const [PhoneNumberErr, setPhoneNumbererr] = useState("");
  const [selectedCountryerr, setselectedCountryerr] = useState("");
  const [selectedStateErr, setselectedStateErr] = useState("");
  const [selectedCityErr, setselectedCityErr] = useState("");
  const [EmailErr, setEmailErr] = useState("");
  const [showPopup, setshowPopup] = useState(false);

  const [Resposne, setResposne] = useState(false);
  const CaptureCaptchaValue = (state: any) => state.CAPTURECAPTCHAVALUE;
  const CaptchaValue = useSelector(CaptureCaptchaValue);
  const storedShippingInfoString = localStorage.getItem("ShippingInfo");

  const $ref = useRef(null);
  const [Captcha, setCaptcha] = useState("");
  const [captchaErr, setCaptchaErr] = useState("");
  const [showCaptcha, setShowCaptcha] = useState(true);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const dispatch = useDispatch();

  async function HandleTabChange() {
    localStorage.removeItem("paypallookup");
    let CaptchaFilledup = "";
    if (localStorage.getItem("userName")) {
      CaptchaFilledup = "";
      setCaptchaErr("");
    } else {
      if (!captchaValue) {
        setCaptchaErr("Please complete the reCAPTCHA challenge");
        CaptchaFilledup = "Please enter valid captcha";
      } else {
        const res = await validateReCaptcha(EnvData, captchaValue);
        if (res.success === false) {
          alert(res.message);
          return false;
        }
      }

      // if (Captcha !== undefined && Captcha !== "" && Captcha !== "") {
      //   if (validateCaptcha(Captcha) === false) {
      //     setCaptchaErr("Please enter valid captcha");
      //     CaptchaFilledup = "Please enter valid captcha";
      //   } else {
      //     setCaptchaErr("");
      //   }
      // } else {
      //   setCaptchaErr("Please enter captcha");
      //   CaptchaFilledup = "Please enter captcha";
      // }
    }
    if (firstName == "" || firstName == undefined || firstName == null) {
      setfirstNameErr("This Field Is Required.");
    } else {
      setfirstNameErr("");
    }
    if (lastName == "" || lastName == undefined || lastName == null) {
      setlastNameErr("This Field Is Required.");
    } else {
      setlastNameErr("");
    }
    if (
      extenderDefaultValue == "" ||
      extenderDefaultValue == undefined ||
      extenderDefaultValue == null
    ) {
      setextenderDefaultValueErr("This Field Is Required.");
    } else {
      setextenderDefaultValueErr("");
    }

    if (selectedZip == "" || selectedZip == undefined || selectedZip == null) {
      setselectedZipErr("This Field Is Required.");
    } else {
      setselectedZipErr("");
    }

    if (PhoneNumber.length == 0) {
      setPhoneNumbererr("");
    } else if (PhoneNumber.length < 10) {
      setPhoneNumbererr("Phone number should have 10  digits.");
    } else if (PhoneNumber.length > 9) {
      setPhoneNumbererr("");
    }

    if (
      selectedCountry == 0 ||
      selectedCountry == undefined ||
      selectedCountry == null
    ) {
      setselectedCountryerr("This Field Is Required.");
    } else {
      setselectedCountryerr("");
    }
    if (
      selectedState == 0 ||
      selectedState == undefined ||
      selectedState == null
    ) {
      setselectedStateErr("This Field Is Required.");
    } else {
      setselectedStateErr("");
    }
    if (
      selectedCity == 0 ||
      selectedCity == undefined ||
      selectedCity == null
    ) {
      setselectedCityErr("This Field Is Required.");
    } else {
      setselectedCityErr("");
    }

    if (props.mail == "" || props.mail == undefined || props.mail == null) {
      setEmailErr("This Field is Required");
      props.EmailValidation("This Field is Required");
    } else {
      const result: boolean = await CheckValidEmailAddress(props.mail);

      if (!result) {
        props.EmailValidation("Please enter a valid email address");
        setEmailErr("Please enter a valid email address");
      } else {
        setEmailErr("");
        setEmailValid(true);
        props.EmailValidation("");
      }
    }
    if (
      firstName !== "" &&
      lastName !== "" &&
      extenderDefaultValue !== "" &&
      selectedZip !== "" &&
      (PhoneNumber.length === 0 || PhoneNumber.length > 9) &&
      selectedCountry !== 0 &&
      selectedState !== 0 &&
      selectedCity !== 0 &&
      props.mail !== "" &&
      props.emailErr === "" &&
      CaptchaFilledup === ""
    ) {
      let ShippingInfo = {
        Firstname: firstName,
        Lastname: lastName,
        AddressLine1: extenderDefaultValue,
        // selectedZip: selectedZip,
        PhoneNumber: PhoneNumber,
        CountryId: selectedCountry,
        StateId: selectedState,
        CityId: selectedCity,
        Email: props.mail,
        Country: selectedCountryValue,
        State: selectedStateValue,
        City: selectedCityValue,
        Aptsuite: "",
        Zip: selectedZip,
        ZipId: ZipId,
      };
      const ShippingInfoString = JSON.stringify(ShippingInfo);
      localStorage.setItem("ShippingInfo", ShippingInfoString);
      localStorage.setItem("BillingInfo", ShippingInfoString);
      localStorage.setItem("EmailInfo", props.mail);

      eventEmitter.emit("triggerTabChange", {
        eventName: "Method",
        captchaValue: CaptchaValue.CaptchaValue,
      });
    } else {
    }
  }

  useEffect(() => {
    if (localStorage.getItem("userName")) {
      setShowCaptcha(false);
    } else {
      if (recaptchaRef.current) {
        setCaptchaValue(null);
        recaptchaRef.current.reset();
      }

      //loadCaptchaEnginge("6");
    }
    eventEmitter.on("triggerPaypalToken", ReceiverToken);
    // eventEmitter.on("triggersearchfilter",  TriggerAttribute);

    return () => {
      // ResetreduxData()
      eventEmitter.off("triggerPaypalToken", ReceiverToken);
    };
  }, []);

  useEffect(() => {
    if (Resposne == true) {
      handleAutotabchange(true);
      if (recaptchaRef.current) {
        setCaptchaValue(null);
        recaptchaRef.current.reset();
      }
      //loadCaptchaEnginge(6);
      setLoading(false);
    }
  }, [Resposne]);

  async function ReceiverToken(args: any) {
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-address-from-paypal?paypalOrderId=${args.token}`;
    let country = false;
    let result = false;
    ClearStateData();
    ClearCountryData();
    ClearCityData();

    let response = await Get_Data(URL).then(async (res: any) => {
      setLoading(true);

      setfirstName(res.data.firstName);
      setlastName(res.data.lastName);
      setextenderDefaultValue(res.data.address1);
      props.setEmailFromPayPal(res.data.email_Address);
      localStorage.setItem("DefaultAddress", res.data.address1);
      localStorage.setItem("BillDefaultAddress", res.data.address1);
      localStorage.setItem("defaultCountry", res.data.country.toUpperCase());
      localStorage.setItem(
        "BilldefaultCountry",
        res.data.country.toUpperCase()
      );
      localStorage.setItem("defaultState", res.data.state.toUpperCase());
      localStorage.setItem("BilldefaultState", res.data.state.toUpperCase());
      localStorage.setItem("defaultCity", res.data.city);
      localStorage.setItem("BilldefaultCity", res.data.city);
      localStorage.setItem("EmailInfo", res.data.email_Address);
      if (res.data.zipCode.includes("-")) {
        let Ziparr = res.data.zipCode.split("-");
        let Zipvalue = Ziparr[0];
        getZipAutoComplete(Zipvalue, "paypal");
        country = await loadCountry(getUrl("Country", Zipvalue!), Zipvalue!);
      } else {
        getZipAutoComplete(res.data.zipCode, "paypal");
        country = await loadCountry(
          getUrl("Country", res.data.zipCode!),
          res.data.zipCode!
        );
      }
      setPhoneNumber(res.data.phoneNo);
      if (country) {
        result = true;
      }
      return result;
    });

    if (country && response) {
      setResposne(true);
      setLoading(false);
    }
  }

  function handleAutotabchange(country: any) {
    if (country !== false) {
      HandleTabChange();
    }
  }

  useEffect(() => {
    if (localStorage.getItem("CustomerInfo")) {
      const loginData = localStorage.getItem("CustomerInfo");
      let loginDetails = loginData ? JSON.parse(loginData) : null;
      setextenderDefaultValue(loginDetails?.shippingAddress1);
      localStorage.setItem("DefaultAddress", loginDetails?.shippingAddress1);

      localStorage.setItem("BillDefaultAddress", loginDetails?.billingAddress1);
      localStorage.setItem("defaultZip", loginDetails.shippingZipCode);
      localStorage.setItem("BilldefaultZip", loginDetails.billingZipCode);
      localStorage.setItem("defaultCountry", loginDetails?.shippingCountryName);
      localStorage.setItem(
        "BilldefaultCountry",
        loginDetails?.billingCountryName
      );
      localStorage.setItem("defaultState", loginDetails?.shippingStateName);
      localStorage.setItem("BilldefaultState", loginDetails?.billingStateName);
      localStorage.setItem("defaultCity", loginDetails?.shippingCityName);
      localStorage.setItem("BilldefaultCity", loginDetails?.billingCityName);
      setselectedZip(loginDetails?.shippingZipCode);
      // setselectedCityValue(loginDetails.shippingCityName);
      // setselectedState(loginDetails.shippingStateId);
      // setselectedCountry(loginDetails.shippingCountryId);
      // setselectedCity(loginDetails.shippingCityId);
      setfirstName(loginDetails.contactFirstName);
      setlastName(loginDetails.contactLastName);
      loadCountry(
        getUrl("Country", loginDetails.shippingZipCode!),
        loginDetails.shippingZipCode!
      );
      setPhoneNumber(loginDetails.shippingPhone);

      let BiilingData = {
        Firstname: loginDetails?.contactFirstName,
        Lastname: loginDetails?.contactLastName,
        AddressLine1: loginDetails?.billingAddress1,
        // selectedZip: selectedZip,
        PhoneNumber: loginDetails?.PhoneNumber,
        CountryId: loginDetails?.billingCountryId,
        StateId: loginDetails?.billingStateId,
        CityId: loginDetails?.billingCityId,
        Email: loginDetails?.customerEmail,
        Country: loginDetails?.billingCountryName,
        State: loginDetails?.billingStateName,
        City: loginDetails?.billingCityName,
        Aptsuite: "",
        Zip: loginDetails?.billingZipCode,
        ZipId: loginDetails?.billingZipCode,
      };

      const BiilingDataString = JSON.stringify(BiilingData);

      localStorage.setItem("BillingInfo", BiilingDataString);
    } else {
      if (localStorage.getItem("DefaultAddress") != "") {
        setextenderDefaultValue(localStorage.getItem("DefaultAddress")!);
      }
      if (localStorage.getItem("defaultZip") != "") {
        setselectedZip(localStorage.getItem("defaultZip")!);
      }
      if (localStorage.getItem("defaultCity") != "") {
        setdefaultCity(localStorage.getItem("defaultCity")!);
      }
      loadCountry(
        getUrl("Country", localStorage.getItem("defaultZip")!),
        localStorage.getItem("defaultZip")!
      );
      if (storedShippingInfoString) {
        const storedShippingInfo = JSON.parse(storedShippingInfoString);
        setfirstName(storedShippingInfo.Firstname);
        setlastName(storedShippingInfo.Lastname);
        setPhoneNumber(storedShippingInfo.PhoneNumber);
      }
    }
    // loadAddressDropdownData(
    //   getUrl("Country", localStorage.getItem("defaultZip")!),
    //   "Country",
    //   localStorage.getItem("defaultZip")!
    // );
  }, []);

  useEffect(() => {
    if (localStorage.getItem("Otherbranch")) {
      const Otherbranch = localStorage.getItem("Otherbranch");
      let otherbranchdata = Otherbranch ? JSON.parse(Otherbranch) : null;
      setextenderDefaultValue(otherbranchdata?.shippingAddress1);
      localStorage.setItem("DefaultAddress", otherbranchdata?.shippingAddress1);

      localStorage.setItem(
        "BillDefaultAddress",
        otherbranchdata?.billingAddress1
      );
      localStorage.setItem("defaultZip", otherbranchdata.shippingZipCode);
      localStorage.setItem("BilldefaultZip", otherbranchdata.billingZipCode);
      localStorage.setItem(
        "defaultCountry",
        otherbranchdata?.shippingCountryName
      );
      localStorage.setItem(
        "BilldefaultCountry",
        otherbranchdata?.billingCountryName
      );
      localStorage.setItem("defaultState", otherbranchdata?.shippingStateName);
      localStorage.setItem(
        "BilldefaultState",
        otherbranchdata?.billingStateName
      );
      localStorage.setItem("defaultCity", otherbranchdata?.shippingCityName);
      localStorage.setItem("BilldefaultCity", otherbranchdata?.billingCityName);
      setselectedZip(otherbranchdata?.shippingZipCode);
      // setselectedCityValue(otherbranchdata.shippingCityName);
      // setselectedState(otherbranchdata.shippingStateId);
      // setselectedCountry(otherbranchdata.shippingCountryId);
      // setselectedCity(otherbranchdata.shippingCityId);
      setfirstName(otherbranchdata.contactFirstName);
      setlastName(otherbranchdata.contactLastName);
      loadCountry(
        getUrl("Country", otherbranchdata.shippingZipCode!),
        otherbranchdata.shippingZipCode!
      );
      setPhoneNumber(otherbranchdata.shippingPhone);

      let BiilingData = {
        Firstname: otherbranchdata?.contactFirstName,
        Lastname: otherbranchdata?.contactLastName,
        AddressLine1: otherbranchdata?.billingAddress1,
        // selectedZip: selectedZip,
        PhoneNumber: otherbranchdata?.PhoneNumber,
        CountryId: otherbranchdata?.billingCountryId,
        StateId: otherbranchdata?.billingStateId,
        CityId: otherbranchdata?.billingCityId,
        Email: otherbranchdata?.customerEmail,
        Country: otherbranchdata?.billingCountryName,
        State: otherbranchdata?.billingStateName,
        City: otherbranchdata?.billingCityName,
        Aptsuite: "",
        Zip: otherbranchdata?.billingZipCode,
        ZipId: otherbranchdata?.billingZipCode,
      };

      const BiilingDataString = JSON.stringify(BiilingData);

      localStorage.setItem("BillingInfo", BiilingDataString);
    }
  }, [localStorage.getItem("Otherbranch")]);

  const LoadZipExtender = (event: any, val: string) => {
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-zipcode?zipCode=${val}`;
    Get_Data(URL)
      .then((res) => {
        //   setMenudata(results.data);

        const transferdata = res.data.map((item: any) => ({
          label: item.zipCode,
          value: item.zipCode,
        }));
        setzipList(transferdata);
      })
      .catch((error) => {});
  };

  const loadExtender = (event: any, txtValue: string) => {
    let eventName: string = "";
    if (txtValue !== "") {
      if (event !== null) {
        eventName = event._reactName;
      }
      txtValue = txtValue.replace(",  ", " ");
      txtValue = txtValue.replace("  ", " ");
      txtValue = txtValue.replace("-", "");
      txtValue = txtValue.replace("_", "");
      txtValue = txtValue.toLowerCase().replace("p.o.box", "");
      txtValue = txtValue.toLowerCase().replace("po box", "");
      txtValue = txtValue.toLowerCase().replace("p o box", "");
      txtValue = txtValue.toLowerCase().replace("pobox", "");
      txtValue = txtValue.toLowerCase().replace("p.o box", "");
      txtValue = txtValue.toLowerCase().replace("p.o box", "");
      localStorage.setItem("DefaultAddress", txtValue);
      localStorage.setItem("BillDefaultAddress", txtValue);

      setextenderDefaultValue(txtValue);

      getAddress(txtValue);
      if (eventName === "onClick") {
        if (txtValue.indexOf(",")) {
          let txtArray = txtValue.split(",");
          localStorage.setItem("DefaultAddress", txtArray[0]);
          localStorage.setItem("BillDefaultAddress", txtArray[0]);
          setextenderDefaultValue(txtArray[0]);
        } else {
          // localStorage.setItem("DefaultAddress", txtValue);
          // setextenderDefaultValue(txtValue);
        }
      }
    } else {
      setextenderDefaultValue(txtValue);
      localStorage.setItem("DefaultAddress", txtValue);
      localStorage.setItem("BillDefaultAddress", txtValue);
    }
  };

  const getAddress = (value: string) => {
    (async () => {
      axios
        .get(
          `https://atlas.microsoft.com/search/address/json?subscription-key=Ny-_UG1MGghhuU2D6ihr8GXBAM5QJp-QLSkFE7Dah6Q&api-version=1.0&limit=80&query=" + ${value}`
        )
        .then((results?) => {
          let transferdata = results?.data["results"]
            .map((item: any) => {
              const { address } = item;
              if (
                address &&
                address.postalCode &&
                address.countryCodeISO3 &&
                address.countrySubdivision &&
                address.countrySecondarySubdivision &&
                address.localName !== "" &&
                !address.postalCode.includes(",") &&
                address.freeformAddress
                  .toLowerCase()
                  .startsWith(value.toLowerCase()) &&
                address.countrySubdivision.length <= 10 &&
                address.countryCodeISO3.length <= 10
              ) {
                let ZipData = [
                  {
                    label: item.address.postalCode,
                    value: `${item.address.postalCode}`,
                  },
                ];

                setzipList(ZipData);
                return {
                  label: item.address.freeformAddress,
                  city: item.address.localName,
                  Zip: item.address.postalCode,
                  country: item.address.countryCodeISO3,
                  state: item.address.countrySubdivision,
                };
              }
            })
            .filter(Boolean);

          setExtenderData(transferdata);
        });
    })();
  };
  function ChangeCountryData(CountryData: any) {
    setcountryData(CountryData);
  }
  function SelectCountry(SelectedData: any) {
    setselectedCountry(SelectedData.countryId);
    setselectedCountryValue(SelectedData.country);
    localStorage.setItem("defaultCountry", SelectedData.country);
    if (
      localStorage.getItem("CustomerInfo") === null ||
      localStorage.getItem("CustomerInfo") === undefined
    ) {
      localStorage.setItem("BilldefaultCountry", SelectedData.country);
    }
  }

  function ClearCountryData() {
    setcountryData([]);

    setselectedCountry(0);
    setselectedCountryValue("");
  }
  function ChangeStateData(StateData: any) {
    setStateData(StateData);
  }

  function SelectState(SelectedData: any) {
    setselectedState(SelectedData.stateId);
    setselectedStateValue(SelectedData.state);
    setselectedStateErr("");
    localStorage.setItem("defaultState", SelectedData.state);
    if (
      localStorage.getItem("CustomerInfo") === null ||
      localStorage.getItem("CustomerInfo") === undefined
    ) {
      localStorage.setItem("BilldefaultState", SelectedData.state);
    }
  }

  function ClearStateData() {
    setStateData([]);
    setselectedState(0);
    setselectedStateValue("");
    setselectedStateErr("Please Select State");
  }

  function ChangeCityData(CityData: any) {
    setcitydata(CityData);
  }

  function SelectCity(SelectedData: any) {
    setselectedCity(SelectedData.cityId);
    setselectedCityValue(SelectedData.city);
    setselectedCityErr("");
    setZipId(SelectedData.zipId);
    localStorage.setItem("defaultCity", SelectedData.city);
    if (
      localStorage.getItem("CustomerInfo") === null ||
      localStorage.getItem("CustomerInfo") === undefined
    ) {
      localStorage.setItem("BilldefaultCity", SelectedData.city);
    }
  }

  function ClearCityData() {
    setcitydata([]);
    setselectedCity(0);
    setselectedCityValue("");
    setselectedCityErr("Please Select City");
    setZipId(0);
    // change("ZipId", "");
  }
  const [zipinputValue, setzipInputValue] = useState("");
  const [countryfocus, setcountryfocus] = useState(false);
  const ziponblur = () => {
    loadZip();
  };
  function loadZip() {
    if (
      zipinputValue !== null &&
      zipinputValue !== "" &&
      zipinputValue !== undefined
    ) {
      var newvalue: any = {
        label: zipinputValue,
        value: zipinputValue,
      };
      setselectedZip(zipinputValue);
      localStorage.removeItem("defaultCountry");
      localStorage.removeItem("BilldefaultCountry");

      localStorage.removeItem("defaultState");
      localStorage.removeItem("BilldefaultState");

      localStorage.removeItem("defaultCity");
      localStorage.removeItem("BilldefaultCity");

      setselectedCountry(0);
      setselectedCity(0);
      setselectedState(0);

      setTimeout(() => {
        getZipAutoComplete(newvalue, "Zip");
      }, 500);
    }
  }

  const getZipAutoComplete = (inputValue: any, event: any) => {
    if (event == "paypal") {
      localStorage.setItem("defaultZip", inputValue);
      localStorage.setItem("BilldefaultZip", inputValue);

      setselectedZip(inputValue);
      // setdefaultCity(inputValue.city);
      loadCountry(
        getUrl("Country", inputValue),

        inputValue
      );
    } else {
      if (inputValue != undefined && inputValue != "") {
        localStorage.setItem("defaultZip", inputValue.label);
        localStorage.setItem("BilldefaultZip", inputValue.label);

        setselectedZip(inputValue.label);
        // setdefaultCity(inputValue.city);
        loadCountry(getUrl("Country", inputValue.label), inputValue.label);
      } else {
        localStorage.removeItem("defaultZip");
        localStorage.removeItem("BilldefaultZip");

        localStorage.removeItem("defaultCountry");
        localStorage.removeItem("BilldefaultCountry");

        localStorage.removeItem("defaultState");
        localStorage.removeItem("BilldefaultState");

        localStorage.removeItem("defaultCity");
        localStorage.removeItem("BilldefaultCity");
        ClearCountryData();
        ClearStateData();
        ClearCityData();
      }
    }
  };

  const getZipcode = (event: any, inputValue: any) => {
    if (inputValue != undefined && inputValue != "") {
      localStorage.setItem("defaultZip", inputValue.Zip);
      localStorage.setItem("defaultCity", inputValue.city);
      localStorage.setItem("BilldefaultZip", inputValue.Zip);
      localStorage.setItem("BilldefaultCity", inputValue.city);

      localStorage.setItem("defaultState", inputValue.state);
      localStorage.setItem("BilldefaultState", inputValue.state);
      localStorage.setItem("defaultCountry", inputValue.country);
      localStorage.setItem("BilldefaultCountry", inputValue.country);

      setselectedZip(inputValue.Zip);
      setdefaultCity(inputValue.city);
      loadCountry(
        getUrl("Country", inputValue.Zip),

        inputValue.Zip
      );
      // loadAddressDropdownData(
      //   getUrl("Country", inputValue.Zip),
      //   "Country",
      //   inputValue.Zip
      // );
    }
  };

  function SelectMultiCity(res: any) {
    let result = false;
    for (var i = 0; i < res.data.length; i++) {
      if (
        res.data[i].city.toUpperCase() ===
        localStorage.getItem("defaultCity")?.toUpperCase()
      ) {
        // setselectedCity(res.data[i].cityId);
        SelectCity(res.data[i]);
        // localStorage.setItem("defaultCity", res.data[i].city);
      }
    }
  }
  const getUrl = (
    flag: string,
    zipcode?: string,
    countryId?: number,
    stateId?: number
  ) => {
    let apiUrl = "";
    if (zipcode === null) {
      zipcode = selectedZip;
    }
    if (countryId === null || countryId === 0) {
      countryId = Number(selectedCountry);
    }
    if (stateId === null || stateId === 0) {
      stateId = Number(selectedState);
    }

    if (flag == "Country") {
      apiUrl = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-country?zipCode=${zipcode}`;
    } else if (flag == "State") {
      apiUrl = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-state?zipCode=${zipcode}&countryId=${countryId}`;
    } else if (flag == "City") {
      apiUrl = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-city?zipCode=${zipcode}&countryId=${countryId}&stateId=${stateId}`;
    }
    return apiUrl;
  };

  async function SelectMultiState(res: any) {
    let result = false;
    for (var i = 0; i < res.data.length; i++) {
      let state = res.data[i].state.toUpperCase().split(" | ")[0].trim();
      let DefaultState = localStorage
        .getItem("defaultState")
        ?.toUpperCase()
        .split(" | ")[0]
        .trim();
      if (state.includes(DefaultState)) {
        // setselectedCity(res.data[i].cityId);
        SelectState(res.data[i]);

        const city = await loadCity(
          getUrl(
            "City",
            localStorage.getItem("defaultZip")!,
            selectedCountry,
            res.data[i].stateId
          ),
          localStorage.getItem("defaultZip")!,
          selectedCountry,
          res.data[i].stateId
        );
        // if(city) {
        result = true;
        // }
        // localStorage.setItem("defaultCity", res.data[i].city);
      }

      // else {
      // let statecode  =   res.data[i].state.toUpperCase().trim('|')
      // }
    }
    return result;
  }

  async function SelectMultiCountry(res: any) {
    const DefaultCountry = localStorage
      .getItem("defaultCountry")
      ?.toUpperCase()
      .split(" | ")[0]
      .trim();
    let countrytrue = false;
    for (var i = 0; i < res.data.length; i++) {
      let country = res.data[i].country.toUpperCase().split(" | ")[0].trim();
      if (country.includes(DefaultCountry)) {
        SelectCountry(res.data[i]);

        const state = await loadState(
          getUrl(
            "State",
            localStorage.getItem("defaultZip")!,
            res.data[i].countryId
          ),
          localStorage.getItem("defaultZip")!,
          res.data[i].countryId
        );
        if (state) {
          countrytrue = true;
        }
        // countrytrue = true
      } else {
      }
    }

    return countrytrue;
  }

  const loadCountry = async (apiUrl: string, zipCode: string) => {
    // return new Promise<boolean>((resolve, reject) => {
    let countrytrue = false;
    await Get_Data(apiUrl)
      .then(async (res) => {
        setcountryData(res.data);
        if (res.data.length === 1) {
          SelectCountry(res.data[0]);
          const state = await loadState(
            getUrl("State", zipCode, res.data[0].countryId),
            zipCode,
            res.data[0].countryId
          );

          if (state) {
            countrytrue = true;
          }

          // resolve(state);
        } else {
          const multi = await SelectMultiCountry(res);
          if (multi == true) {
            countrytrue = true;
          }
          // resolve(false);
        }
      })
      .catch((error) => {
        // reject(error);
      });

    // resolve(countrytrue);
    // });
    return countrytrue;
  };
  const loadState = async (
    apiUrl: string,
    zipCode: string,
    countryId: number
  ) => {
    // return new Promise<boolean>((resolve, reject) => {
    let result = false;
    await Get_Data(apiUrl)
      .then(async (res) => {
        setStateData(res.data);
        if (res.data.length === 1) {
          SelectState(res.data[0]);
          localStorage.setItem("defaultState", res.data[0].state);
          localStorage.setItem("BilldefaultState", res.data[0].state);
          const city = await loadCity(
            getUrl("City", zipCode, countryId, res.data[0].stateId),
            zipCode,
            countryId,
            res.data[0].stateId
          );

          // if (city === true) {
          //   result = true;
          // }
          // resolve(result);
          if (city) {
            result = true;
          }
        } else {
          const multi = await SelectMultiState(res);
          if (multi == true) {
            result = true;
          }
        }
      })
      .catch((error) => {
        console.log("Error in " + error);
        // reject(error);
      });
    // });

    return result;
  };

  const loadCity = async (
    apiUrl: string,
    zipCode: string,
    countryId: number,
    cityId: number
  ) => {
    // return new Promise<boolean>((resolve, reject) => {
    let result = false;
    await Get_Data(apiUrl)
      .then((res) => {
        setcitydata(res.data);
        if (res.data.length === 1) {
          SelectCity(res.data[0]);
        } else {
          SelectMultiCity(res);
        }

        result = true;
        // resolve(result);
      })
      .catch((error) => {
        console.log("Error in " + error);
        // reject(error);
      });
    return result;
    // });
  };

  const loadAddressDropdownData = (
    apiUrl: string,
    flag: string,
    zipCode?: string
  ) => {
    if (zipCode === undefined) {
      zipCode = localStorage.getItem("defaultZip")!;
    }

    Get_Data(apiUrl)
      .then((res) => {
        if (flag == "Country") {
          setcountryData(res.data);
          if (res.data.length === 1) {
            SelectCountry(res.data[0]);

            loadAddressDropdownData(
              getUrl("State", zipCode, res.data[0].countryId),
              "State"
            );
          } else {
            setselectedCountry(0);
          }
        } else if (flag == "State") {
          setStateData(res.data);
          if (res.data.length === 1) {
            setselectedState(res.data[0].stateId);

            loadAddressDropdownData(
              getUrl("City", zipCode, selectedCountry, res.data[0].stateId),
              "City"
            );
          } else {
            setselectedState(0);
          }
        } else if (flag == "City") {
          setcitydata(res.data);
          if (res.data.length === 1) {
            setselectedCity(res.data[0].cityId);
          } else {
            SelectMultiCity(res);
          }
        }
        return {
          label: res.data,
          value: `${res.data} `,
        };
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };

  function handleLoadOptions(name: string) {
    let URL = "";
    if (name == "Country") {
      URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-country?zipCode=${selectedZip}`;
    } else if (name == "State") {
      URL = `${
        EnvData?.REACT_APP_APIURL
      }/microsite-api-v1/get-state?zipCode=${selectedZip}&countryId=${Number(
        selectedCountry
      )}`;
    } else if (name == "City") {
      URL = `${
        EnvData?.REACT_APP_APIURL
      }/microsite-api-v1/get-city?zipCode=${selectedZip}&countryId=${Number(
        selectedCountry
      )}&stateId=${Number(selectedState)}`;
    }

    Get_Data(URL)
      .then((res) => {
        if (name == "Country") {
          setcountryData(res.data);
        } else if (name == "State") {
          setStateData(res.data);
        } else if (name == "City") {
          setcitydata(res.data);
        }
        return {
          label: res.data,
          value: `${res.data} `,
        };
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  }

  // const handleInputnumberChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const value = event.target.value.replace(/[^0-9()-]/g, "");
  //   if (value.length>9) {
  //     setPhoneNumber(value);
  //   }
  //   else {
  //     setPhoneNumbererr("This Field Is Required.");
  //   }

  // };

  const handleInputnumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.replace(/[^0-9]/g, "");

    if (value.length == 10 && value.length <= 11) {
      setPhoneNumber(value);
      setPhoneNumbererr("");
    } else if (value.length === 0) {
      setPhoneNumber("");
      // setPhoneNumbererr("This Field Is Required.");
    } else {
      setPhoneNumber(value);
      // setTimeout(() => {
      //   setPhoneNumbererr("Phone number should have 10 to 11 digits.");
      // }, 100);
    }
  };

  const closeLightbox = () => {
    setshowPopup(false);
  };

  const handleDropDown = (event?: any, newvalue?: any, name?: string) => {
    if (newvalue == "Country") {
      ChangeCountryData(event);
      SelectCountry(event);
      // CountryChange(event.countryId);
      // getUrl("State", selectedZip, event.countryId),
      loadState(
        getUrl("State", selectedZip, event.countryId),
        selectedZip,
        event.countryId
      );
    } else if (newvalue == "State") {
      ChangeStateData(event);
      SelectState(event);
      loadCity(
        getUrl("City", selectedZip, selectedCountry, event.stateId),
        selectedZip,
        selectedCountry,
        event.stateId
      );
      // StateChange(event.stateId);
    } else if (newvalue == "City") {
      ChangeCityData(event);
      SelectCity(event);
    } else if ((newvalue == "Zip" && event == null) || undefined || "") {
      setselectedZip("");

      // ClearCountryData()
      // ClearStateData()
      // citylist([]);
      setzipList([]);

      ClearCountryData();

      ClearStateData();

      ClearCityData();
    } else {
      setselectedZip(event?.value);

      ClearStateData();

      ClearCityData();

      ClearCountryData();
    }
  };

  function enterCaptcha(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let captchaVal = restrictedInputs(event.target.value);
    setCaptcha(captchaVal.toString());
    dispatch(addCaptchaValue(event.target.value.toString()));
  }

  // const reloadCaptcha = () => {
  //   loadCaptchaEnginge("6");
  //   setCaptchaValue(null)
  // };

  const RECAPTCHA_SITE_KEY = EnvData?.REACT_APP_RECAPTCHA_SITEKEY;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value: any) => {
    setCaptchaValue(value);
    dispatch(addCaptchaValue(value));
    console.log("Captcha value:", value);
  };

  if (Loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label="First Name"
            name="Firstname"
            variant="standard"
            value={firstName}
            onChange={(e) => {
              setfirstName(e.target.value);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;

              if (inputElement.value == "") {
                setfirstNameErr("This Field Is Required.");
              } else {
                setfirstNameErr("");
              }
            }}
            error={
              firstNameErr !== "" &&
              firstNameErr !== undefined &&
              firstNameErr !== null
            }
            helperText={firstNameErr}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="Lastname"
            variant="standard"
            value={lastName}
            error={lastNameErr !== ""}
            helperText={lastNameErr}
            onChange={(e) => {
              setlastName(e.target.value);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;

              if (inputElement.value == "") {
                setlastNameErr("This Field Is Required.");
              } else {
                setlastNameErr("");
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Autocomplete
            options={Array.isArray(extenderData) ? extenderData : []}
            disablePortal
            freeSolo
            fullWidth
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;
              if (extenderDefaultValue == "") {
                setextenderDefaultValueErr("This Field Is Required.");
              } else {
                setextenderDefaultValueErr("");
              }
            }}
            // error={extenderDefaultValueErr}
            // helperText={extenderDefaultValueErr}
            onInputChange={(event, txtValue) =>
              // txtValue.length >= 3 &&
              loadExtender(event, txtValue)
            }
            onChange={getZipcode}
            // onChange={(event, newvalue) =>
            //   getZipAutoComplete(event)
            // }

            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                name="AddressLine1"
                variant="standard"
                error={extenderDefaultValueErr !== ""}
                helperText={extenderDefaultValueErr}
              />
            )}
            value={extenderDefaultValue}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            label="Apt, Suite etc. (optional)"
            name="Aptsuite"
            variant="standard"
            value={appSuite}
            onChange={(e) => {
              setappSuite(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <Autocomplete
            disablePortal
            options={Array.isArray(zipList) ? zipList : []}
            fullWidth
            value={selectedZip}
            inputValue={zipinputValue}
            onInputChange={(event, newvalue) => {
              setzipInputValue(newvalue);
              newvalue.length >= 3 && LoadZipExtender(event, newvalue);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;
              if (selectedZip == "") {
                setselectedZipErr("This Field Is Required.");
              } else {
                setselectedZipErr("");
              }
            }}
            onChange={(event, newvalue) => getZipAutoComplete(newvalue, "Zip")}
            onBlur={ziponblur}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zip Code"
                name="Zip"
                variant="standard"
                value={selectedZip}
                error={selectedZipErr !== ""}
                helperText={selectedZipErr}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    loadZip();
                    e.preventDefault();
                    setcountryfocus(true);
                  }
                }}
              />
            )}
            clearOnBlur={false}
            clearOnEscape={false}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Country
            </InputLabel>

            <Select
              fullWidth
              name="Country"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              sx={{ width: "100%", maxHeight: "300px" }}
              label=" Country"
              value={selectedCountry || ""}
              autoFocus={countryfocus}
              onClick={() => handleLoadOptions("Country")}
              onBlur={() => {
                if (selectedCountry == 0) {
                  setselectedCountryerr("This Field Is Required.");
                } else {
                  setselectedCountryerr("");
                }
                setcountryfocus(false);
              }}
              error={selectedCountryerr !== ""}
              inputProps={{
                id: "select-control",
              }}
            >
              {Array.isArray(countryData) ? (
                countryData.map((res: ICountryProps, index: number) => (
                  <MenuItem
                    key={index}
                    value={res.countryId}
                    onClick={(e) => handleDropDown(res, "Country")}
                  >
                    {res.country}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No countries available</em>
                </MenuItem>
              )}
            </Select>
            {selectedCountryerr !== "" && (
              <FormHelperText style={{ color: "red" }}>
                Please select Country.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              sx={{ width: "100%", maxHeight: "300px" }}
              label="State"
              name="State"
              value={selectedState || ""}
              onFocus={() => handleLoadOptions("State")}
              error={selectedStateErr !== ""}
              onBlur={() => {
                if (selectedState == 0) {
                  setselectedStateErr("This Field Is Required.");
                } else {
                  setselectedStateErr("");
                }
              }}
            >
              {Array.isArray(stateData) && stateData.length > 0 ? (
                stateData.map((res: IStateProps, index: number) => (
                  <MenuItem
                    key={index}
                    value={res.stateId}
                    onClick={(e) => handleDropDown(res, "State")}
                  >
                    {res.state}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No states available</em>
                </MenuItem>
              )}
            </Select>
            {selectedStateErr !== "" && (
              <FormHelperText style={{ color: "red" }}>
                Please select State.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">City</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              sx={{ width: "100%", maxHeight: "300px" }}
              label="City"
              name="City"
              value={selectedCity || ""}
              onFocus={() => handleLoadOptions("City")}
              error={selectedCityErr !== ""}
              onBlur={() => {
                if (selectedCity == 0) {
                  setselectedCityErr("This Field Is Required.");
                } else {
                  setselectedCityErr("");
                }
              }}
            >
              {Array.isArray(citydata) && citydata.length > 0 ? (
                citydata.map((res: ICityProps, index: number) => (
                  <MenuItem
                    key={index}
                    value={res.cityId}
                    onClick={(e) => handleDropDown(res, "City")}
                  >
                    {res.city}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No cities available</em>
                </MenuItem>
              )}
            </Select>
            {selectedCityErr !== "" && (
              <FormHelperText style={{ color: "red" }}>
                Please select City.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label="Phone #"
            name="Phone"
            variant="standard"
            value={PhoneNumber}
            inputProps={{
              pattern: "[0-9]*",
              minLength: 10,
              maxLength: 10,
            }}
            onChange={(e: any) => {
              handleInputnumberChange(e);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;
              const value = inputElement.value.replace(/[^0-9()-]/g, "");
              // if (value== "") {
              //   setPhoneNumbererr("This Field Is Required.");
              // } else

              if (value.length > 9 || value.length == 0) {
                setPhoneNumbererr("");
              }
            }}
            error={PhoneNumberErr !== ""}
            helperText={PhoneNumberErr}
          />
        </Grid>
        {showCaptcha === true ? (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  For your security and ours, please enter the captcha below
                  before proceeding.
                </Typography>
                <Stack>
                  <div className="ReloadCaptcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={handleCaptchaChange}
                    />

                    {/* <canvas
                      id="canv"
                      width="100"
                      height="15"
                      ref={$ref}
                    ></canvas>
                    <Button onClick={reloadCaptcha} variant="text">
                      <CachedIcon />
                    </Button>
                    <div className="EnterAboveText">
                      <TextField
                        id="user_captcha_input"
                        label="Enter Captcha"
                        variant="outlined"
                        onChange={(e) => {
                          enterCaptcha(e);
                        }}
                      />
                    </div> */}
                  </div>
                </Stack>
                {captchaErr !== "" && (
                  <FormHelperText style={{ color: "red" }}>
                    {captchaErr}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>

      <Button
        fullWidth
        variant="contained"
        // onClick={handlecontinueToShip}
        onClick={HandleTabChange}
        sx={{ mt: 3 }}
      >
        Continue To Shipping Options
      </Button>
    </>
  );
};
export default AddressFilling;
